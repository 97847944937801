import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _directive_clipboard = _resolveDirective("clipboard")!

  return (!_ctx.title)
    ? _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
        key: 0,
        variant: "text",
        icon: "assignment",
        size: "small",
        style: {"opacity":"0.7"},
        disabled: !_ctx.modelValue
      }, null, 8, ["disabled"])), [
        [_directive_clipboard, _ctx.modelValue],
        [_directive_clipboard, _ctx.onCopy, "success"],
        [_directive_clipboard, _ctx.onError, "error"]
      ])
    : _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
        key: 1,
        "prepend-icon": "assignment",
        color: _ctx.color,
        size: _ctx.size,
        variant: _ctx.variant,
        disabled: !_ctx.modelValue
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.title), 1)
        ]),
        _: 1
      }, 8, ["color", "size", "variant", "disabled"])), [
        [_directive_clipboard, _ctx.modelValue],
        [_directive_clipboard, _ctx.onCopy, "success"],
        [_directive_clipboard, _ctx.onError, "error"]
      ])
}