
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'ClipboardComponent',

    props: {
      modelValue: { type: String, default: '' },
      title: { type: String, default: null },
      color: { type: String, default: 'primary' },
      size: { type: String, default: 'small' },
      variant: { type: String, default: 'elevation' },
    },

    methods: {
      onCopy() {
        this.$store.commit('showSnackbar', {
          text: '클립보드에 복사 하였습니다.',
        })
      },

      onError() {
        this.$store.commit('showSnackbar', {
          text: '클립보드에 복사가 불가능 합니다.',
          type: 'error',
        })
      },
    },
  })
