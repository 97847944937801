import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "error-banner bg-error d-flex align-center pl-4 elevation-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Snackbar = _resolveComponent("Snackbar")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, {
    id: "app",
    class: _normalizeClass(_ctx.$store.getters.zoomOut ? 'zoom-out' : '')
  }, {
    default: _withCtx(() => [
      (_ctx.$store.getters.errorBanner)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_v_icon, { class: "pr-4" }, {
              default: _withCtx(() => [
                _createTextVNode("error")
              ]),
              _: 1
            }),
            _createElementVNode("div", null, "네트워크 에러 " + _toDisplayString(_ctx.$store.getters.errorBannerText), 1),
            _createVNode(_component_v_spacer),
            _createVNode(_component_v_btn, {
              icon: "close",
              size: "small",
              variant: "text",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$store.commit('closeErrorBanner')))
            })
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_router_view),
      _createVNode(_component_Snackbar)
    ]),
    _: 1
  }, 8, ["class"]))
}