import Axios from 'axios'

const state = {
  banks: [] as {
    bankCd: string
    bankName: string
    bankUseYn: string
    nicepayBankUseYn: string
    escrowBankUseYn: string
  }[],
}

const getters = {
  bankItemsSeller: (state) => {
    // 셀러용 은행 리스트
    let bankItems = state.banks.map((bank) => {
      return {
        title: bank.bankName,
        value: bank.bankCd,
        isOverseas: ['096'].includes(bank.bankCd),
      }
    })

    bankItems = [
      ...bankItems,
      { title: 'HANMI BANK', value: '900', isOverseas: true },
      { title: 'KANSAI MIRAI BANK', value: '901', isOverseas: true },
      { title: 'KEB 하나은행(JP)', value: '902', isOverseas: true },
    ]

    return bankItems
  },

  bankItemsCustomer: (state) => {
    // 구매자용 은행 리스트
    return state.banks.map((bank) => {
      return {
        title: bank.bankName,
        value: bank.bankCd,
        isOverseas: ['096'].includes(bank.bankCd),
      }
    })
  },

  bankItemsNE: (state) => {
    // 구매자: 에스크로우 사용 은행 리스트
    const filteredBankItems = state.banks
      .filter((bank) => bank.escrowBankUseYn === 'Y')
      .map((bank) => {
        return {
          title: bank.bankName,
          value: bank.bankCd,
          isOverseas: ['096'].includes(bank.bankCd),
        }
      })

    return filteredBankItems
  },

  bankItemsNA: (state) => {
    // 구매자: 나이스페이 사용 은행 리스트
    const filteredBankItems = state.banks
      .filter((bank) => bank.nicepayBankUseYn === 'Y')
      .map((bank) => {
        return {
          title: bank.bankName,
          value: bank.bankCd,
          isOverseas: ['096'].includes(bank.bankCd),
        }
      })

    return filteredBankItems
  },

  bankItemsNENA: (state) => {
    // 구매자: 에스크로우 & 나이스페이 사용 은행 리스트
    const filteredBankItems = state.banks
      .filter((bank) => bank.escrowBankUseYn === 'Y' && bank.nicepayBankUseYn === 'Y')
      .map((bank) => {
        return {
          title: bank.bankName,
          value: bank.bankCd,
          isOverseas: ['096'].includes(bank.bankCd),
        }
      })

    return filteredBankItems
  },

  banksItems: (state) => {
    return state.banks
  },
}

const mutations = {
  banks(state, payload) {
    state.banks = payload
  },
}

const actions = {
  async fetchBanks({ commit }) {
    const { data } = await Axios.get(`${process.env.VUE_APP_SERVER_ENDPOINT_ORDER}/banks`)
    commit('banks', data.data.banks)
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
