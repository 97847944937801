
  import { defineComponent } from 'vue'
  import 'v-calendar/dist/style.css'
  import Snackbar from './components/SnackbarComponent.vue'
  import { SellerApi } from '@/api'
  import { mapGetters } from 'vuex'
  import { useStore } from 'vuex'
  import { useHead } from '@vueuse/head'

  export default defineComponent({
    name: 'App',

    components: {
      Snackbar,
    },

    setup() {
      const store = useStore()
      const headers = { headers: { 'Service-No': store.getters.sellerInfo.serviceNo || localStorage.getItem('serviceNo') } }
      const sellerApi = new SellerApi(headers)

      // 페이지 타이틀, favicon 설정
      store.dispatch('setDocumentTitle')
      useHead({
        title: store.getters.documentTitle,
        meta: [
          { property: 'og:title', content: store.getters.documentTitle },
          { name: 'twitter:title', content: store.getters.documentTitle },
        ],
      })

      return {
        sellerApi,
      }
    },

    computed: {
      ...mapGetters(['token', 'isLogin', 'channelCode']),
      isHelpi() {
        return this.$store.getters.channelCode === 'helpi'
      },
    },

    watch: {
      channelCode: {
        handler() {
          this.$store.dispatch('setDocumentTitle')

          useHead({
            title: this.$store.getters.documentTitle,
            meta: [
              { property: 'og:title', content: this.$store.getters.documentTitle },
              { name: 'twitter:title', content: this.$store.getters.documentTitle },
            ],
          })
        },
      },
    },

    created() {
      // 다중 브라우저 다중 계정 방지 or 새로고침 시 store token 정보 최신화
      // 새 탭으로 다른 계정 로그인 시 seller 정보 조회 API로 200 확인 후 store에 token 저장
      if (this.isLogin && !this.token) {
        this.sellerApi.getSellerInfo(localStorage.getItem('sellerNo')).then(() => {
          this.$store.commit('setToken', localStorage.getItem('accessToken'))
        })
      }

      // 전체 카테고리 목록을 가져와서 store에 저장
      this.$store.dispatch('fetchChannelSectionItems')

      // 마지막 리로드 시간을 저장
      localStorage.setItem('lastReload', new Date().getTime().toString())
    },

    mounted() {
      // 은행 목록을 가져와서 store에 저장
      this.$store.dispatch('fetchBanks')
    },
  })
