import Api, { ApiConfig } from '@/api/base/api'
import { CancelTokenSource } from 'axios'

class CodiApi extends Api {
  public cancelToken: CancelTokenSource | null = null

  constructor(configs?: ApiConfig) {
    super({
      baseURL: `${process.env.VUE_APP_SERVER_ENDPOINT_ADMIN}`,
      ...configs,
    })
  }

  /**
   * [코디 목록] 조회
   */
  getRegisterable() {
    return this.requestGet<Common.TApiResponseWrapper>('/coordi/registerable')
  }
}

export default CodiApi
