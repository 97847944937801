import { defineComponent } from 'vue'
import Axios from 'axios'

export default defineComponent({
  name: 'EmailVerificationComponent',

  props: {
    modelValue: { type: String, required: true },
    phoneVerified: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
  },

  emits: ['update:modelValue', 'update:phoneVerified'],

  data() {
    return {
      value: this.modelValue, // 전화번호
      verifying: false, // 인증번호 확인 중
      sending: false, // 인증번호 전송 중
      checking: false, // 인증번호 확인 중
      verified: this.phoneVerified, // 인증 완료
      code: '', // 인증번호
      timeout: 0, // 인증번호 전송 후 남은 시간
      interval: 0, // 인증번호 전송 후 남은 시간 갱신용

      // Validation
      phoneRules: [(v) => !!v || '입력해주세요.'],
      codeRules: [
        (v) => !!v || '입력해주세요.',
        (v) => /^\d+$/.test(v) || '숫자만 입력해주세요.',
        (v) => v.length === 6 || '6자리 숫자를 입력해주세요.',
      ],
    }
  },

  computed: {
    // 남은시간 100분율
    timeoutPercent() {
      return (this.timeout / (60 * 5)) * 100
    },

    // 남은 초를 분:초로 변환
    timeoutMinute() {
      const minute = Math.floor(this.timeout / 60)
      const second = this.timeout % 60

      return `${minute < 10 ? '0' : ''}${minute}:${second < 10 ? '0' : ''}${second}`
    },

    phoneNoValid() {
      return this.value.length > 0 && this.phoneRules.every((rule) => rule(this.value) === true)
    },
  },

  watch: {
    modelValue() {
      this.value = this.modelValue

      if (!this.value) {
        this.verified = false
      }
    },
    phoneVerified() {
      this.verified = this.phoneVerified
    },
    verified() {
      this.$emit('update:phoneVerified', this.verified)
    },
  },

  mounted() {
    //
  },

  methods: {
    // 인증번호 전송
    sendPhoneVerification() {
      this.sending = true
      clearInterval(this.interval)

      Axios.post(`${process.env.VUE_APP_SERVER_ENDPOINT_SELLER}/auth/sms/certification`, {
        phoneNumber: this.value,
        isLogin: false,
      }).then(() => {
        this.verifying = true
        this.sending = false
        this.code = ''
        this.$store.commit('showSnackbar', { text: '인증번호가 전송되었습니다.' })

        // 인증번호 전송 후 5분 Countdown
        this.timeout = 60 * 5 // 5분
        this.interval = setInterval(() => {
          this.timeout--
          if (this.timeout === 0) {
            clearInterval(this.interval)
            this.verifying = false
          }
        }, 1000) // 1초마다
      })
    },

    onChangePhone() {
      // 숫자나 대쉬가 아닌 문자를 모두 제거
      this.value = this.value.replace(/[^0-9-]/g, '')

      // ###-####-#### 형식으로 입력
      if (this.value.length > 3) {
        if (this.value[3] !== '-') {
          this.value = `${this.value.slice(0, 3)}-${this.value.slice(3)}`
        }
      }
      if (this.value.length > 8) {
        if (this.value[8] !== '-') {
          this.value = `${this.value.slice(0, 8)}-${this.value.slice(8)}`
        }
      }

      // 글자수 넘으면 자르기
      if (this.value.length > 13) {
        this.value = this.value.slice(0, 13)
      }

      this.$emit('update:modelValue', this.value)
    },

    // 인증번호 확인
    confirmVerify() {
      this.checking = true

      Axios.get(`${process.env.VUE_APP_SERVER_ENDPOINT_SELLER}/auth/sms/certification`, {
        params: { phoneNumber: this.value, code: this.code },
      })
        .then(() => {
          this.checking = false
          this.verified = true
          this.verifying = false
          this.code = ''
          clearInterval(this.interval)

          this.$store.commit('showSnackbar', { text: '인증되었습니다.' })

          this.$emit('update:phoneVerified', this.verified)
        })
        .catch(() => {
          this.$store.commit('closeErrorBanner')
          this.$store.commit('showSnackbar', { text: '인증번호가 일치하지 않습니다.', type: 'error' })
          this.checking = false
        })
    },
  },
})
