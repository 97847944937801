import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex mt-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_select = _resolveComponent("v-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_spacer),
    _createVNode(_component_v_select, {
      modelValue: _ctx.size,
      "onUpdate:modelValue": [
        _cache[0] || (_cache[0] = ($event: any) => ((_ctx.size) = $event)),
        _ctx.changeSize
      ],
      items: _ctx.limitList,
      suffix: "개씩 보기",
      density: "compact",
      "hide-details": "",
      class: "flex-grow-0"
    }, null, 8, ["modelValue", "items", "onUpdate:modelValue"])
  ]))
}