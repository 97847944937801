<template>
  <v-menu v-model="menu" :close-on-content-click="false" min-width="0" @after-leave="submit">
    <template #activator="{ props }">
      <!-- 텍스트 필드 -->
      <v-text-field
        v-bind="props"
        ref="textField"
        :value="label"
        readonly
        :density="density"
        :placeholder="placeholder"
        :disabled="disabled"
        :hide-details="hideDetails"
        :prepend-inner-icon="noIcon ? '' : 'event'"
        :append-inner-icon="label && clearable ? 'cancel' : ''"
        :suffix="suffix"
        :prefix="prefix"
        class="cursor-pointer white-bg"
        :class="[smallText ? 'small-text' : '', dirty ? 'dirty' : '', dimed ? 'dimed' : '']"
        :error-messages="required && !label ? '필수 입력입니다.' : []"
        @click:append-inner="clear" />
    </template>

    <!-- 달력 -->
    <v-card v-click-outside="onClickOutside">
      <v-date-picker
        v-model="date"
        color="indigo"
        :model-config="{ type: 'string', mask: format }"
        :min-date="min"
        :max-date="max"
        :is-range="range"
        :first-day-of-week="2"
        :mode="mode"
        :masks="{ title: 'YYYY년 M월' }" />
      <v-card-actions>
        <v-spacer />
        <v-btn size="small" class="text-white bg-primary elevation-2" @click="submit"> 확인 </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script type="ts" src="./DatePickerComponent.ts" />

<style lang="scss">
  .small-text.v-text-field input {
    font-size: 14px;
  }
</style>
