const state = {
  createStepAccess: 1,
  createCurrentStep: 1,
  productSettings: null,
  productImages: [],
  productTags: null,
  productContent: null,
  saleSettings: null,
  optionSettings: null,
  productDetails: null,
  tagDisabled: false,
  omniTags: null,
  omniLoading: false,
}

const getters = {
  createStepAccess: (state) => state.createStepAccess,
  createCurrentStep: (state) => state.createCurrentStep,
  productSettings: (state) => state.productSettings,
  productImages: (state) => state.productImages,
  productTags: (state) => state.productTags,
  productContent: (state) => state.productContent,
  saleSettings: (state) => state.saleSettings,
  optionSettings: (state) => state.optionSettings,
  productDetails: (state) => state.productDetails,
  tagDisabled: (state) => state.tagDisabled,
  omniTags: (state) => state.omniTags,
  omniLoading: (state) => state.omniLoading,
}

const mutations = {
  createStepAccess(state, step) {
    state.createStepAccess = step
  },
  createCurrentStep(state, step) {
    state.createCurrentStep = step
  },
  productSettings: (state, settings) => (state.productSettings = settings),
  productImages: (state, images) => (state.productImages = images),
  productTags: (state, tags) => (state.productTags = tags),
  productContent: (state, content) => (state.productContent = content),
  saleSettings: (state, settings) => (state.saleSettings = settings),
  optionSettings: (state, settings) => (state.optionSettings = settings),
  productDetails: (state, productDetails) => (state.productDetails = productDetails),
  tagDisabled: (state, value: boolean) => (state.tagDisabled = value),
  omniTags: (state, tags) => (state.omniTags = tags),
  omniLoading: (state, value: boolean) => (state.omniLoading = value),
}

const actions = {
  resetCreateProduct({ commit }) {
    commit('createStepAccess', 1)
    commit('createCurrentStep', 1)
    commit('productSettings', null)
    commit('productImages', [])
    commit('productTags', null)
    commit('productContent', null)
    commit('saleSettings', null)
    commit('optionSettings', null)
    commit('productDetails', null)
    commit('tagDisabled', false)
    commit('omniTags', null)
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
