
  import { defineComponent, PropType } from 'vue'

  type Contents = {
    title: string
    description: string
    content: string
  }[]

  export default defineComponent({
    name: 'DevToolComponent',

    props: {
      contents: { type: Array as PropType<Contents>, default: () => [] },
    },
  })
