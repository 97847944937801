
  import { defineComponent, PropType } from 'vue'

  type Period = {
    start: string
    end: string
  }

  export default defineComponent({
    name: 'QuickPeriodComponent',

    props: {
      modelValue: { type: [Object, String] as PropType<Period>, default: () => ({ start: '', end: '' }) },
      disabled: { type: Boolean, default: false },
      isProduct: { type: Boolean, default: false },
    },

    emits: ['update:modelValue'],

    data() {
      return {
        hide: this.disabled,
        date: this.modelValue,
      }
    },

    watch: {
      disabled(val: boolean) {
        this.hide = val
      },
    },

    methods: {
      setTotal() {
        this.date = {
          start: '2016-07-01',
          end: this.$moment().format('YYYY-MM-DD'),
        }
        this.$emit('update:modelValue', this.date)
      },

      setToday() {
        this.date = {
          start: this.$moment().format('YYYY-MM-DD'),
          end: this.$moment().format('YYYY-MM-DD'),
        }
        this.$emit('update:modelValue', this.date)
      },

      setWeek() {
        this.date = {
          start: this.$moment().subtract(1, 'week').format('YYYY-MM-DD'),
          end: this.$moment().format('YYYY-MM-DD'),
        }
        this.$emit('update:modelValue', this.date)
      },

      setMonth() {
        this.date = {
          start: this.$moment().subtract(1, 'month').format('YYYY-MM-DD'),
          end: this.$moment().format('YYYY-MM-DD'),
        }
        this.$emit('update:modelValue', this.date)
      },

      setQuarter() {
        this.date = {
          start: this.$moment().subtract(3, 'month').format('YYYY-MM-DD'),
          end: this.$moment().format('YYYY-MM-DD'),
        }
        this.$emit('update:modelValue', this.date)
      },

      setYear() {
        this.date = {
          start: this.$moment().subtract(1, 'year').format('YYYY-MM-DD'),
          end: this.$moment().format('YYYY-MM-DD'),
        }
        this.$emit('update:modelValue', this.date)
      },
    },
  })
