import { defineComponent } from 'vue'

export default defineComponent({
  name: 'EmailVerificationComponent',

  props: {
    modelValue: { type: String, required: true },
    emailVerified: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
  },

  emits: ['update:modelValue', 'update:emailVerified'],

  data() {
    return {
      value: this.modelValue,
      verifying: false,
      verified: this.emailVerified,
      verifyNumber: '',
      timeout: 0,
      interval: 0,
      code: 0,
      codeRules: [(v) => !!v || '입력해주세요.', (v) => v.length === 6 || '6자리 숫자를 입력해주세요.'],
    }
  },

  watch: {
    modelValue() {
      this.value = this.modelValue
    },
    emailVerified() {
      this.verified = this.emailVerified
    },
  },

  mounted() {
    //
  },

  methods: {
    sendPhoneVerification() {
      clearInterval(this.interval)

      this.verifying = true

      this.code = Math.floor(100000 + Math.random() * 900000)

      alert(`인증코드: ${this.code}`)

      this.$store.commit('showSnackbar', { text: '인증번호가 전송되었습니다.' })

      this.timeout = 180
      this.interval = setInterval(() => {
        this.timeout--
        if (this.timeout === 0) {
          clearInterval(this.interval)
          this.verifying = false
        }
      }, 1000) // 1초마다
    },

    confirmVerify() {
      if (+this.verifyNumber === this.code) {
        this.verified = true
        this.verifying = false
        this.$store.commit('showSnackbar', { text: '인증되었습니다.' })
        this.$emit('update:emailVerified', true)
      } else {
        this.$store.commit('showSnackbar', { text: '인증번호가 일치하지 않습니다.' })
        this.$emit('update:emailVerified', false)
      }
    },
  },
})
