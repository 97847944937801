
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'CategorySelect',

    props: {
      categoryNo: { type: Number, default: 0 },
      categoryName: { type: String, default: '' },
    },

    emits: ['update:categoryNo', 'update:categoryName', 'clear'],

    data() {
      return {
        menu: false,
        disabled: this.isSelected,
        depth: 0,
        loading: false,
        category1Items: [] as { value: number; title: string }[],
        category2Items: [] as { value: number; title: string }[],
      }
    },

    computed: {
      number: {
        get() {
          return this.categoryNo
        },
        set(value) {
          this.$emit('update:categoryNo', value)
        },
      },
      name: {
        get() {
          return this.categoryName
        },
        set(value) {
          this.$emit('update:categoryName', value)
        },
      },
    },

    watch: {
      isSelected(value) {
        this.disabled = value
      },
      menu(value) {
        // 스토어 카테고리가 하나일 경우 자동 선택
        if (value && this.$store.getters.sellerSectionItems.length === 1) {
          this.onSectionSelect(this.$store.getters.sellerSectionItems[0].value)
        }
      },
    },

    methods: {
      onSectionSelect(id: number) {
        this.loading = true

        this.$store.dispatch('getProductCategoryItems', id).then((res) => {
          this.category1Items = res
          this.depth = 1
          this.loading = false
        })
      },
      onCategory1Select(id: number) {
        this.loading = true

        this.$store.dispatch('getProductCategoryItems', id).then((res) => {
          this.category2Items = res
          this.depth = 2
          this.loading = false
        })
      },
      onCategory2Select(category: { value: number; title: string }) {
        this.number = category.value
        this.name = category.title
        this.menu = false
        this.depth = 0
      },
      change() {
        if (!this.menu) this.depth = 0
      },
      clear() {
        this.number = null
        this.name = null
        this.$emit('clear')
      },
      close() {
        this.menu = false
        this.depth = 0
      },
    },
  })
