import Api, { ApiConfig } from '@/api/base/api'
import { CancelTokenSource, AxiosRequestConfig } from 'axios'

class OrderApi extends Api {
  public cancelToken: CancelTokenSource | null = null

  constructor(configs?: ApiConfig) {
    super({
      baseURL: `${process.env.VUE_APP_SERVER_ENDPOINT_ORDER}`,
      ...configs,
    })
  }

  /**
   * [주문취소 정보 조회]
   * @param orderDetailCds 주문상세코드[]
   */
  getOrderCancelInfo(orderDetailCds: string[]) {
    return this.requestPost<Common.TApiResponseWrapper<Order.ResponseOrderCancelInfo>>('/claim/order-cancel/info', {
      orderDetailCds: orderDetailCds,
    })
  }

  /**
   * [주문취소 요청]
   * @param data 주문취소 요청 데이터
   * @param config axios request config
   */
  putStatusOrderCancel(data: Order.ClamStatusOrderCancelRequest[], config?: AxiosRequestConfig) {
    return this.requestPut<Common.TApiResponseWrapper<Order.ClamStatusOrderCancelResponse>>(
      '/claim/v1/status/order-cancel',
      {
        data: data,
      },
      config
    )
  }

  /**
   * [환불승인 요청] 다이얼로드 오픈 (오픈 전 조회)
   * @param orderDetailCd 주문상세코드
   */
  getRefundConfirm(orderDetailCd: string) {
    return this.requestGet<Common.TApiResponseWrapper<Order.ResponseRefundConfirmInfo>>(`/claim/refund-confirm/${orderDetailCd}`)
  }

  /**
   * [환불요청] 다이얼로드 오픈 (오픈 전 조회)
   * @param orderDetailCd 주문상세코드
   */
  getRefundRequest(orderDetailCd: string) {
    return this.requestGet<Common.TApiResponseWrapper<Order.ResponseRefundRequestInfo>>(`/claim/refund-request/${orderDetailCd}`)
  }

  /**
   * [환불요청]
   * @param data 환불요청 데이터
   * @param config axios request config
   */
  putStatusRefundRequest(data: Order.RefundRequestRequest, config?: AxiosRequestConfig) {
    return this.requestPut<Common.TApiResponseWrapper<Order.ClaimStatusMetaResponse>>(
      '/claim/v1/status/refund-request',
      { data: [data] },
      config
    )
  }

  /**
   * [환불승인요청]
   * @param data 환불승인요청 데이터
   * @param config axios request config
   */
  putStatusRefundConfirm(data: Order.RefundConfirmRequest, config?: AxiosRequestConfig) {
    return this.requestPut<Common.TApiResponseWrapper<Order.ClaimStatusMetaResponse>>(
      '/claim/v1/status/refund-confirm',
      { data: [data] },
      config
    )
  }

  /**
   * [환불요청취소]
   * @param data 환불요청취소 데이터
   * @param config axios request config
   */
  putStatusRefundCancelRequest(data: Order.RefundCancelRequest[], config?: AxiosRequestConfig) {
    return this.requestPut<Common.TApiResponseWrapper<Order.ClaimStatusMetaResponse>>(
      '/claim/v1/status/refund-cancel',
      { data: data },
      config
    )
  }

  /**
   * [배송준비 상태변경]
   * @param data 배송준비 데이터
   * @param config axios request config (
   */
  putStatusDeliveryReady(data: Order.OrderDetailInterface[], config: AxiosRequestConfig) {
    return this.requestPut<Common.TApiResponseWrapper<Order.ClaimStatusMetaResponse>>(
      '/order/v1/status/delivery-ready',
      { data: data },
      config
    )
  }

  /**
   * [배송중 상태변경]
   * @param data 배송중 데이터
   * @param config axios request config (
   */
  putStatusDeliveryStart(data: Order.OrderDetailInterface[], config: AxiosRequestConfig) {
    return this.axios.put('/order/v1/status/delivery-start', { data: data }, config)
  }

  /**
   * [배송지연안내]
   * @param data 배송지연안내 데이터
   * @param config axios request config
   */
  postStatusDelayDelivery(data: Order.DeliveryDelayRequest[], config?: AxiosRequestConfig) {
    return this.requestPost<Common.TApiResponseWrapper<Order.ClaimStatusMetaResponse>>(
      '/order/v1/status/delay-delivery',
      { data: data },
      config
    )
  }

  /**
   * [묶음배송 요청]
   * @param data 묶음배송처리 데이터
   * @param config axios request config
   */
  putDeliveryBundle(data: Order.RequestOrderDeliveryBundle, config?: AxiosRequestConfig) {
    return this.requestPut<Common.TApiResponseWrapper<Order.OrderCommonResponse>>('/order/delivery/bundle', data, config)
  }

  /**
   * [묶음배송 정보조회]
   * @param data 묶음배송 주문 데이터
   * @param config axios request config
   */
  postDeliveryBundleInfo(data: Order.RequestOrderDeliveryInfo, config?: AxiosRequestConfig) {
    return this.requestPost<Common.TApiResponseWrapper<Order.ResponseOrderDeliveryBundleInfo>>('/order/delivery/bundle/Info', data, config)
  }

  /**
   * [송장정보 조회]
   * @param data 송장정보 주문 데이터
   * @param config axios request config
   */
  postDeliveryInvoiceInfo(data: Order.RequestOrderDeliveryInfo, config?: AxiosRequestConfig) {
    return this.requestPost<Common.TApiResponseWrapper<Order.ResponseOrderDeliveryInvoiceInfo>>(
      '/order/delivery/invoice/Info',
      data,
      config
    )
  }

  /**
   * [송장정보 변경 요청]
   * @param data 변경 송장정보 데이터
   * @param config axios request config
   */
  putDeliveryInvoceInfo(data: Order.RequestOrderDeliveryInvoice, config?: AxiosRequestConfig) {
    return this.requestPut<Common.TApiResponseWrapper<Order.ClaimStatusMetaResponse>>('/order/delivery/invoice', data, config)
  }

  /**
   * [배송완료 상태변경]
   * @param data 주문 데이터
   * @param config axios request config
   */
  putStatusDeliveryDone(data: Order.OrderDetailInterface[], config?: AxiosRequestConfig) {
    return this.requestPut<Common.TApiResponseWrapper<Order.ClaimStatusMetaResponse>>(
      '/order/v1/status/delivery-done',
      { data: data },
      config
    )
  }

  /**
   * [구매확정 진행/보류 상태변경]
   * @param data 주문 데이터
   * @param config axios request config
   */
  patchStatusPurchaseConfirmHold(data: Order.PurchaseConfirmHoldRequest[], config?: AxiosRequestConfig) {
    return this.requestPatch<Common.TApiResponseWrapper<Order.OrderCommonResponse>>(
      '/order/v1/status/purchase-confirm-hold',
      { data: data },
      config
    )
  }

  /**
   * [상품준비 상태변경]
   * @param data 주문 데이터
   * @param config axios request config
   */
  putStatusProductPrepare(data: Order.OrderDetailInterface[], config?: AxiosRequestConfig) {
    return this.requestPut<Common.TApiResponseWrapper<Order.ClaimStatusMetaResponse>>(
      '/order/v1/status/product-prepare',
      { data: data },
      config
    )
  }

  /**
   * [반품진행 상태변경]
   * @param data 주문 데이터
   * @param config axios request config
   */
  putStatusReturnGoods(data: Order.OrderDetailInterface[], config?: AxiosRequestConfig) {
    return this.requestPut<Common.TApiResponseWrapper<Order.ClaimStatusMetaResponse>>(
      '/claim/v1/status/return-goods',
      { data: data },
      config
    )
  }

  /**
   * 엑셀다운로드 요청
   * @param URI 엑셀다운로드 URI
   * @param data 엑셀다운로드 요청 데이터
   */
  postExcelDownload<T extends object>(URI: string, data: T) {
    return this.requestPost<Common.TApiResponseWrapper>(URI, data)
  }

  /**
   * 주문/취소환불 목록 조회
   * @param URL API URL
   * @param params 주문/취소환불 목록 조회 데이터
   */
  getOrdersList<T extends object>(URL: string, params: T) {
    return this.requestGet<Common.TApiResponseWrapper<Order.ResponseOrderList>>(URL, params)
  }

  /**
   * 배송지연/구매확정보류 Count 조회
   */
  getOrderInfoCountDelayHold() {
    return this.axios.get<Common.TApiResponseWrapper<Order.ResponseDelayHoldCount>>('/order/info/count/delay-hold')
  }

  /**
   * 주문상세 조회
   * @param orderDetailCd 주문상세코드
   */
  getOrderDetail(orderDetailCd: string) {
    return this.axios.get<Common.TApiResponseWrapper<Order.ResponseOrderDetail>>(`/order/detail/${orderDetailCd}`)
  }
}

export default OrderApi
