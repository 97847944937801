import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a024a1f"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "d-flex align-center justify-center py-12"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!

  return (_openBlock(), _createBlock(_component_v_menu, {
    id: "test",
    ref: "test",
    modelValue: _ctx.menu,
    "onUpdate:modelValue": [
      _cache[2] || (_cache[2] = ($event: any) => ((_ctx.menu) = $event)),
      _ctx.change
    ],
    "close-on-content-click": false
  }, {
    activator: _withCtx(({ props }) => [
      _createVNode(_component_v_text_field, _mergeProps({
        modelValue: _ctx.name,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.name) = $event))
      }, props, {
        density: "compact",
        placeholder: "카테고리",
        clearable: "",
        readonly: "",
        "hide-details": "",
        close: "",
        class: "white-bg cursor-pointer",
        "onClick:clear": _ctx.clear
      }), {
        "append-inner": _withCtx(() => [
          _createVNode(_component_v_icon, {
            class: _normalizeClass(["append-inner-icon", _ctx.menu ? 'opened' : '']),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.menu = !_ctx.menu))
          }, {
            default: _withCtx(() => [
              _createTextVNode("arrow_drop_down")
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        _: 2
      }, 1040, ["modelValue", "onClick:clear"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["elevation-6 rounded pa-1 bg-white category-menu", _ctx.name ? 'selected' : ''])
      }, [
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_v_progress_circular, {
                indeterminate: "",
                size: "32",
                color: "grey"
              })
            ]))
          : _createCommentVNode("", true),
        (_ctx.depth === 0 && !_ctx.loading)
          ? (_openBlock(), _createBlock(_component_v_list, {
              key: 1,
              class: "mx-n1 my-n1"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$store.getters.sellerSectionItems, (section) => {
                  return (_openBlock(), _createBlock(_component_v_list_item, {
                    key: section.value,
                    onClick: ($event: any) => (_ctx.onSectionSelect(section.value))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_list_item_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(section.title), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                }), 128))
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.depth === 1 && !_ctx.loading)
          ? (_openBlock(), _createBlock(_component_v_list, {
              key: 2,
              class: "mx-n1 my-n1"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.category1Items, (category1) => {
                  return (_openBlock(), _createBlock(_component_v_list_item, {
                    key: category1.value,
                    onClick: ($event: any) => (_ctx.onCategory1Select(category1.value))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_list_item_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(category1.title), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                }), 128))
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.depth === 2)
          ? (_openBlock(), _createBlock(_component_v_list, {
              key: 3,
              class: "mx-n1 my-n1"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.category2Items, (category2) => {
                  return (_openBlock(), _createBlock(_component_v_list_item, {
                    key: category2.value,
                    onClick: ($event: any) => (_ctx.onCategory2Select(category2))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_list_item_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(category2.title), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                }), 128))
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ], 2)
    ]),
    _: 1
  }, 8, ["modelValue", "onUpdate:modelValue"]))
}