import moment from 'moment'
import _ from 'lodash'

const filters = {
  // 숫자 3자리 단위 콤마 표시
  number(value: number) {
    if (!value) {
      return value === 0 ? '-' : value
    } else if (value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  },

  // 날짜 포맷
  dateTime(value: string) {
    if (!value) {
      return value
    } else if (value) {
      return moment(value).format('YYYY-MM-DD HH:mm')
    }
  },

  // 쿼리에서 빈 값 제거
  trimQuery(param: object) {
    const query = _.cloneDeep(param)

    for (const key in query) {
      if ([null, 'false', undefined].includes(query[key]) || query[key].length < 1) {
        delete query[key]
      }
    }
    return query
  },

  // 단위수 휴먼에러 방지용 가격 글자표시
  priceToText(price: number) {
    let text = ''
    const man = Math.floor(price / 10000)
    const chun = price % 10000

    if (!man && !chun) return ''

    if (man) text += `${man}만`
    if (chun) text += ` ${chun}`

    return `${text}원`
  },

  // &lt; &gt; &quot; &apos; 특수기호 변환
  specialCharToText(text: string) {
    if (typeof text !== 'string') return text

    return text
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&quot;/g, '"')
      .replace(/&apos;/g, "'")
  },
}

export default filters
