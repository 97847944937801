<template>
  <div class="label">은행명 *</div>
  <v-autocomplete
    id="bank"
    ref="bank"
    v-model="bank"
    :items="bankItems"
    density="compact"
    placeholder="은행을 선택해 주세요."
    no-data-text="검색 결과가 없습니다."
    :readonly="verified || verifying"
    :rules="rules.bank"
    :menu-props="{ maxHeight: 400 }"
    @keydown.up.prevent.stop.capture=""
    @keydown.down.prevent.stop.capture=""
    @keydown.enter="onEnter" />
  <div class="label">계좌번호 *</div>
  <div class="d-flex align-start">
    <v-text-field
      ref="account"
      v-model="account"
      density="compact"
      placeholder="계좌번호를 입력하세요."
      :rules="rules.account"
      :readonly="verified || verifying || !bank"
      @keydown.enter="verify">
      <template v-if="useVerification && verified && !disabled" #append-inner>
        <v-btn variant="plain" size="small" style="margin-top: -2px" @click="reset">변경</v-btn>
        <v-icon color="success">check_circle</v-icon>
      </template>
    </v-text-field>
    <v-btn
      v-if="useVerification && !verified && !isOverseas"
      flat
      color="primary-lighten-1"
      class="ml-1"
      style="height: 41px"
      :disabled="isExcept || !account.length"
      :loading="verifying"
      @click="verify">
      인증
    </v-btn>
  </div>

  <div class="label">예금주 *</div>
  <v-text-field
    ref="accountHolder"
    v-model="accountHolder"
    density="compact"
    :placeholder="isOverseas ? '예금주를 입력해 주세요.' : '계좌번호를 인증해 주세요.'"
    :readonly="isReadOnlyAccountHolder"
    :rules="isOverseas || !useVerification || (useVerification && isExcept) ? rules.accountHolder : []"
    @keydown.enter="verify" />

  <v-checkbox
    v-if="useExceptVerification"
    v-model="isExcept"
    label="계좌 인증 제외"
    density="compact"
    :hide-details="false"
    class="except-verification"></v-checkbox>
</template>

<script type="ts" src="./BankVerificationComponent.ts" />
