<template>
  <div class="d-flex align-start">
    <v-text-field
      v-model="value"
      density="compact"
      placeholder="010-####-####"
      :readonly="verified || readonly"
      :rules="phoneRules"
      :disabled="sending"
      class="bg-white"
      @update:model-value="onChangePhone">
      <template v-if="verified && !readonly" #append-inner>
        <v-btn variant="plain" size="small" style="margin-top: -2px" @click="verified = false">변경</v-btn>
        <v-icon color="success">check_circle</v-icon>
      </template>
    </v-text-field>

    <template v-if="!verified && !readonly">
      <v-btn
        v-if="!verifying"
        flat
        color="primary-lighten-1"
        class="ml-1"
        style="height: 42px"
        :disabled="!phoneNoValid"
        :loading="sending"
        @click="sendPhoneVerification">
        인증번호 발송
      </v-btn>
      <v-btn
        v-else
        flat
        color="grey-lighten-3 text-chacoal"
        class="ml-1"
        style="height: 42px"
        :disabled="sending"
        @click="sendPhoneVerification">
        인증번호 재발송
      </v-btn>
    </template>
  </div>

  <template v-if="verifying">
    <div class="label">인증번호입력 ({{ timeoutMinute }} 남음)</div>
    <div class="d-flex align-start">
      <v-text-field
        v-model.trim="code"
        density="compact"
        placeholder="6자리 숫자"
        :rules="codeRules"
        :disabled="checking || timeoutPercent <= 0 || sending"
        @keydown.enter="confirmVerify">
        <template #loader>
          <v-progress-linear
            :active="verifying"
            :model-value="timeoutPercent"
            :color="timeoutPercent > 20 ? 'secondary' : 'error'"
            absolute
            height="3" />
        </template>
      </v-text-field>

      <v-btn
        flat
        color="primary"
        class="ml-1"
        style="height: 42px"
        :disabled="code.length !== 6 || sending"
        :loading="checking"
        @click="confirmVerify">
        확인
      </v-btn>
    </div>
  </template>
</template>

<script type="ts" src="./PhoneVerificationComponent.ts" />
