import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: _ctx.open,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.open) = $event)),
    "max-width": _ctx.maxWidth,
    persistent: _ctx.persistent
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_text, { class: "py-12" }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default"),
              _withDirectives(_createVNode(_component_v_text_field, { ref: "confirmInput" }, null, 512), [
                [_vShow, _ctx.inputShow]
              ])
            ]),
            _: 3
          }),
          _createVNode(_component_v_card_actions, { class: "px-4 pb-4" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_spacer),
              (!_ctx.noCancel)
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 0,
                    onClick: _ctx.cancel
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" 취소 ")
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : _createCommentVNode("", true),
              _createVNode(_component_v_btn, {
                ref: "confirmBtn",
                variant: "elevated",
                color: "primary",
                onClick: _ctx.confirm
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.confirmText), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 3
      })
    ]),
    _: 3
  }, 8, ["modelValue", "max-width", "persistent"]))
}