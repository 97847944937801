import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from '@/store'

// 페이지 접근 권한 체크
const requireAuth = () => (to, from, next) => {
  if (to.path === '/dashboard') {
    return next()
  }

  const menus = store.getters.menus

  let path = `${to.path}`

  const listRouters = ['/product-list', '/order-list', '/cancel-list', '/codi-list', '/notice', '/partner-payout']

  if (listRouters.some((router) => to.path.includes(router))) {
    path = `/${to.path.split('/')[1]}`
  }

  if (menus) {
    for (const menu of menus) {
      if (menu.menuUrl === path) {
        return next()
      } else if (menu.childMenu) {
        for (const childMenu of menu.childMenu) {
          if (childMenu.menuUrl === path) {
            return next()
          }
        }
      }
    }
  } else {
    return next()
  }

  next('/not-authorized')
}

const routes: Array<RouteRecordRaw> = [
  { path: '/ping', redirect: '/' }, // for health check
  { path: '/login', component: () => import(/* webpackChunkName: "login" */ '@/views/Login/Login.vue') },
  { path: '/logout', component: () => import(/* webpackChunkName: "logout" */ '@/views/Login/Logout.vue') },
  { path: '/register', component: () => import(/* webpackChunkName: "register" */ '@/views/Register/Register.vue') },
  { path: '/register-select', component: () => import(/* webpackChunkName: "register-select" */ '@/views/Register/RegisterSelect.vue') },
  {
    path: '/policy',
    component: () => import(/* webpackChunkName: "policy-layout" */ '@/views/Layout/PolicyLayout.vue'),
    children: [
      { path: '', redirect: 'policy/personal' },
      { path: 'personal', component: () => import(/* webpackChunkName: "personal" */ '@/views/Policy/Personal.vue') },
      {
        path: 'collectUsePersonalRequired',
        component: () => import(/* webpackChunkName: "collectUsePersonalRequired" */ '@/views/Policy/CollectUsePersonalRequired.vue'),
      },
      {
        path: 'collectUsePersonalOptional',
        component: () => import(/* webpackChunkName: "collectUsePersonalOptional" */ '@/views/Policy/CollectUsePersonalOptional.vue'),
      },
    ],
  },
  {
    path: '',
    component: () => import(/* webpackChunkName: "layout" */ '@/views/Layout/Layout.vue'),
    children: [
      { path: '', redirect: '/dashboard' },
      {
        path: '/dashboard',
        meta: { title: '대쉬보드' },
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/Dashboard.vue'),
      },
      {
        path: '/product-list',
        meta: { title: '상품 리스트' },
        beforeEnter: requireAuth(),
        component: () => import(/* webpackChunkName: "product-list" */ '@/views/ProductList/ProductList.vue'),
      },
      {
        path: '/product-list/:id',
        name: 'product-detail',
        meta: { title: '상품 상세보기' },
        beforeEnter: requireAuth(),
        props: true,
        component: () => import(/* webpackChunkName: "product-detail" */ '@/views/ProductDetail/ProductDetail.vue'),
      },
      {
        path: '/product/create',
        meta: { title: '상품 등록' },
        beforeEnter: requireAuth(),
        component: () => import(/* webpackChunkName: "product-create" */ '@/views/ProductDetail/ProductCreate.vue'),
      },
      {
        path: '/product/price',
        name: 'price-list',
        meta: { title: '가격/재고 관리' },
        beforeEnter: requireAuth(),
        component: () => import(/* webpackChunkName: "price-list" */ '@/views/Price/PriceList.vue'),
      },
      {
        path: '/product/display',
        meta: { title: '스토어 진열관리' },
        beforeEnter: requireAuth(),
        component: () => import(/* webpackChunkName: "product-display" */ '@/views/Display/Display.vue'),
      },
      {
        path: '/connect-category',
        meta: { title: '연동상품 카테고리' },
        // beforeEnter: requireAuth(),
        component: () => import(/* webpackChunkName: "connect-category" */ '@/views/Connect/Category.vue'),
      },
      {
        path: '/connect-product',
        meta: { title: '연동상품관리' },
        // beforeEnter: requireAuth(),
        component: () => import(/* webpackChunkName: "connect-product" */ '@/views/Connect/ConnectProduct.vue'),
      },
      {
        path: '/order-list',
        name: 'order-list',
        beforeEnter: requireAuth(),
        meta: { title: '주문 관리', group: 'order' },
        component: () => import(/* webpackChunkName: "order-list" */ '@/views/OrderList/OrderList.vue'),
      },
      {
        path: '/order-list/:id',
        meta: { title: '주문 상세보기' },
        beforeEnter: requireAuth(),
        props: true,
        component: () => import(/* webpackChunkName: "order-detail" */ '@/views/OrderDetail/OrderDetail.vue'),
      },
      {
        path: '/cancel-list',
        beforeEnter: requireAuth(),
        meta: { title: '취소/환불 관리', group: 'cancel' },
        component: () => import(/* webpackChunkName: "cancel-list" */ '@/views/OrderList/OrderList.vue'),
      },
      {
        path: '/cancel-list/:id',
        meta: { title: '주문 상세보기' },
        beforeEnter: requireAuth(),
        props: true,
        component: () => import(/* webpackChunkName: "cancel-detail" */ '@/views/OrderDetail/OrderDetail.vue'),
      },
      {
        path: '/codi-list',
        meta: { title: '코디 관리' },
        beforeEnter: requireAuth(),
        component: () => import(/* webpackChunkName: "codi-list" */ '@/views/CodiList/CodiList.vue'),
      },
      {
        path: '/codi-list/create',
        meta: { title: '코디 추가' },
        props: true,
        beforeEnter: requireAuth(),
        component: () => import(/* webpackChunkName: "codi-detail" */ '@/views/CodiDetail/CodiDetail.vue'),
      },
      {
        path: '/codi-list/:id',
        meta: { title: '코디 상세보기' },
        props: true,
        beforeEnter: requireAuth(),
        component: () => import(/* webpackChunkName: "codi-detail" */ '@/views/CodiDetail/CodiDetail.vue'),
      },
      {
        path: '/qna',
        meta: { title: '문의/답변' },
        beforeEnter: requireAuth(),
        component: () => import(/* webpackChunkName: "qna" */ '@/views/Qna/Qna.vue'),
      },
      {
        path: '/notice',
        meta: { title: '일반공지' },
        beforeEnter: requireAuth(),
        component: () => import(/* webpackChunkName: "notice" */ '@/views/Notice/NoticeList.vue'),
      },
      {
        path: '/notice/:id',
        meta: { title: '일반공지 상세보기' },
        beforeEnter: requireAuth(),
        props: true,
        component: () => import(/* webpackChunkName: "notice-detail" */ '@/views/Notice/NoticeDetail.vue'),
      },
      {
        path: '/download',
        meta: { title: '엑셀 다운로드' },
        beforeEnter: requireAuth(),
        component: () => import(/* webpackChunkName: "download" */ '@/views/Download/Download.vue'),
      },
      {
        path: '/partner-payout',
        meta: { title: '정산 리스트' },
        beforeEnter: requireAuth(),
        component: () => import(/* webpackChunkName: "partner-payout" */ '@/views/PartnerPayout/PartnerPayoutList.vue'),
      },
      {
        path: '/partner-payout/:id',
        meta: { title: '정산 리스트' },
        beforeEnter: requireAuth(),
        props: true,
        component: () => import(/* webpackChunkName: "partner-payout-detail" */ '@/views/PartnerPayout/PartnerPayoutDetail.vue'),
      },
      {
        path: '/ledgers',
        meta: { title: '정산예정' },
        beforeEnter: requireAuth(),
        component: () => import(/* webpackChunkName: "ledgers" */ '@/views/Ledgers/Ledgers.vue'),
      },
      {
        path: '/sales-vat',
        meta: { title: '부가세 참고자료' },
        beforeEnter: requireAuth(),
        component: () => import(/* webpackChunkName: "sales-vat" */ '@/views/SalesVat/SalesVat.vue'),
      },
      {
        path: '/review',
        meta: { title: '리뷰' },
        beforeEnter: requireAuth(),
        component: () => import(/* webpackChunkName: "review" */ '@/views/Review/Review.vue'),
      },
      {
        path: '/report',
        meta: { title: '판매분석' },
        beforeEnter: requireAuth(),
        component: () => import(/* webpackChunkName: "report" */ '@/views/Report/Report.vue'),
      },
      {
        path: '/settings',
        meta: { title: '셀러 설정' },
        beforeEnter: requireAuth(),
        component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings/Settings.vue'),
      },
      {
        path: '/penalty',
        meta: { title: '페널티 관리' },
        beforeEnter: requireAuth(),
        component: () => import(/* webpackChunkName: "penalty" */ '@/views/Penalty/Penalty.vue'),
      },
      {
        path: '/shipping-policy',
        meta: { title: '배송정책 관리' },
        beforeEnter: requireAuth(),
        component: () => import(/* webpackChunkName: "shipping-policy" */ '@/views/ShippingPolicy/ShippingPolicy.vue'),
      },
      {
        path: '/playground',
        meta: { title: 'Playground' },
        component: () => import(/* webpackChunkName: "playground" */ '@/views/Playground/Playground.vue'),
      },
    ],
  },
  { path: '/not-authorized', component: () => import(/* webpackChunkName: "not-authorized" */ '@/views/NotAuthorized.vue') },
  { path: '/:pathMatch(.*)*', component: () => import(/* webpackChunkName: "not-found" */ '@/views/NotFound.vue') },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior() {
    return { top: 0 }
  },
  routes,
})

router.beforeEach(function (to, from, next) {
  const childrenPath = routes.find((route) => route.path === '')?.children?.map((child) => child.path)

  // localStorage에 값이 제대로 없으면 로그아웃 처리
  if (
    childrenPath?.includes(to.path) &&
    from.path !== '/login' &&
    (!localStorage.getItem('sellerNo') ||
      !localStorage.getItem('sellerName') ||
      !localStorage.getItem('viewType') ||
      !localStorage.getItem('sellerStatus') ||
      (localStorage.getItem('viewType') === '1' && !localStorage.getItem('no')))
  ) {
    return next('/logout')
  }

  // 로그인 페이지 접근시 로컬스토리지에 토큰 있으면 '/'로 보내기
  if (to.path === '/login' && localStorage.getItem('accessToken')) {
    return next('/')
  }

  // 엑셀다운로드 비밀번호 설정 안되어 있고 dashboard에서 다른페이지로 이동하면 dashboard로
  if (from.path === '/' && localStorage.getItem('isExcelPassword') === 'false' && to.path !== '/dashboard') {
    return next('/dashboard')
  }

  // 다른페이지로 이동할 때 진행중 로딩 끄기
  if (from.path !== to.path) {
    store.commit('endLoading')
  }

  // 에러배너 닫기
  if (from.path !== to.path) {
    store.commit('closeErrorBanner')
  }

  const accessToken = localStorage.getItem('accessToken')

  const exceptionPaths = [
    '/login',
    '/register',
    '/register-select',
    '/policy',
    '/policy/personal',
    '/policy/collectUsePersonalRequired',
    '/policy/collectUsePersonalOptional',
  ]
  if (accessToken || to.path === '/login' || exceptionPaths.includes(to.path)) {
    // 토큰 있으면

    // 가격 페이지에서 다른 페이지로 이동할 때
    if (from.path === '/product/price' && to.path !== '/product/price' && store.getters.dirty) {
      if (window.confirm('저장되지 않은 정보는 사라집니다. 페이지를 나가시겠습니까?')) {
        store.commit('setPageTitle', to.meta.title)
        return next()
      } else {
        return
      }
    }

    // 페이지 타이틀 설정
    store.commit('setPageTitle', to.meta.title)
    next()
  } else {
    // 토큰 없으면
    next('/login')
  }
})

export default router
