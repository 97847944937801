import Api, { ApiConfig } from '@/api/base/api'
import { CancelTokenSource, AxiosRequestConfig } from 'axios'

class QnaApi extends Api {
  public cancelToken: CancelTokenSource | null = null

  constructor(configs?: ApiConfig) {
    super({
      baseURL: `${process.env.VUE_APP_SERVER_ENDPOINT_ADMIN}`,
      ...configs,
    })
  }

  /**
   * [문의글] 조회
   * @param params 검색 필터
   */
  getQuestions(params: Qna.RequestSimplePageSellerProductInquiryInfo) {
    return this.requestGet<Common.TApiResponseWrapper<Qna.SimplePageSellerProductInquiryInfo>>('/questions', {
      ...params,
    })
  }

  /**
   * [문의답변 등록] 요청
   * @param data {questionNo: 문의글 번호; payload: 문의답변 등록 요청 데이터}
   * @param config axios request config
   */
  postQnaAnswers(data: { questionNo: number; payload: Qna.SellerCreateAnswer }, config?: AxiosRequestConfig) {
    return this.requestPost<Common.TApiResponseWrapper<Order.ClamStatusOrderCancelResponse>>(
      `/questions/${data.questionNo}/answers`,
      data.payload,
      config
    )
  }

  /**
   * [문의답변 수정] 요청
   * @param data {questionNo: 문의글 번호; payload: 문의답변 등록 요청 데이터; answerNo: 문의답변 번호}
   * @param config axios request config
   */
  patchQnaAnswers(data: { questionNo: number; answerNo: number; payload: Qna.SellerCreateAnswer }, config?: AxiosRequestConfig) {
    return this.requestPatch<Common.TApiResponseWrapper<Order.ClamStatusOrderCancelResponse>>(
      `/questions/${data.questionNo}/answers/${data.answerNo}`,
      data.payload,
      config
    )
  }

  /**
   * [문의답변 삭제] 요청
   * @param params {questionNo: 문의글 번호; answerNo: 문의답변 번호; sellerNo?: 판매자 번호}
   * @param config axios request config
   */
  deleteQnaAnswers(params: Qna.DeleteSellerAnswer, config?: AxiosRequestConfig) {
    return this.requestDelete<Common.TApiResponseWrapper<Order.ClamStatusOrderCancelResponse>>(
      `/questions/${params.questionNo}/answers/${params.answerNo}`,
      config
    )
  }
}

export default QnaApi
