import Axios, { AxiosError, AxiosInstance, AxiosRequestConfig, CancelTokenSource, AxiosResponse } from 'axios'
import Store from '@/store'
import router from '@/router'

export type ApiConfig = {
  token?: string
}

export type AxiosInstanceConfig = {
  baseURL: string
  headers?: { [key: string]: string | number | object | null | undefined | void }
  [key: string]: string | number | object | null | undefined | void
}

const SEC = 1000

abstract class Api {
  public token: string | null | undefined
  public domain: string
  public axios: AxiosInstance
  public TIME_OUT = SEC * 30
  public store = Store
  public cancelToken: CancelTokenSource | null = null

  constructor({ baseURL, token, ...configs }: ApiConfig & AxiosInstanceConfig) {
    this.domain = baseURL
    this.token = token || Store.getters.token || localStorage.getItem('accessToken')

    const config = {
      headers: { 'Service-No': this.store.getters.serviceNo },
      ...configs,
    }

    if (this.token) {
      config['headers']['authorization'] = this.token
    }

    // admin-api 공통처리
    if (this.domain.includes('https://admin-api')) {
      config['headers']['referer-type'] = 'new-admin'
    }
    // order-api 공통처리
    if (this.domain.includes('https://order-api')) {
      config['headers']['Seller-No'] = this.store.getters.sellerNo || localStorage.getItem('sellerNo')
    }

    this.axios = Axios.create({
      baseURL: this.domain,
      timeout: this.TIME_OUT,
      ...config,
    })

    this.axios.interceptors.response.use(undefined, this.onError.bind(this))
    this.axios.interceptors.request.use(this.onRequest.bind(this), undefined)
  }

  onServerErrorHandler(error: AxiosError<Common.TApiResponseWrapper>) {
    if (error.response && error.response.data?.error?.code) {
      switch (error.response.data.error.code) {
        // 인증만료 or 토큰 유효성 검사 실패
        case 401:
        case 403:
          router.push('/logout')
          this.store.commit('showSnackbar', { text: '인증이 만료되었습니다. 로그인을 해주세요.' })
          break
        case 503:
          this.store.commit('setMaintenanceText', { text: error.response.data.error.message, isMaintenance: true })
          break
        case 500:
        case 400:
          // order-api 에러 공통처리
          // admin-api 에러 공통처리
          // * 그 외 API 는 에러처리 별도 구현 필요
          if (error.request?.responseURL.includes('https://order-api') || error.request?.responseURL.includes('https://admin-api')) {
            this.store.commit('closeErrorBanner')
            this.store.commit('endLoading')
            this.store.commit('showSnackbar', { text: error.response.data.error.message, type: 'error' })
          }
          break
        case 404:
          this.store.commit('endLoading')
          this.store.commit('openErrorBanner', { text: error.response.data.error.message, type: 'error' })
          break
      }
    }
  }

  onError(error: AxiosError<Common.TApiResponseWrapper>) {
    switch (true) {
      case Axios.isCancel(error): // Axios 중복 요청 에러 처리
        return Promise.reject(error)
      case !!error.response: // 서버 에러 처리
        this.onServerErrorHandler(error)
        return Promise.reject(error)
      default:
        this.store.commit('endLoading')
        this.store.commit('setMaintenanceText', { text: null, isMaintenance: false })
        this.store.commit('openErrorBanner', error.response?.data?.error?.message || error)
        return Promise.reject(error)
    }
  }

  onParamsEscape(params: URLSearchParams) {
    const URL = new URLSearchParams()

    for (const key in params) {
      if (params[key] !== null && params[key] !== undefined) {
        URL.append(key, params[key])
      }
    }

    return URL
  }

  onQueryStringEscape(url: string) {
    if (url.includes('?')) {
      const [baseUrl, queryString] = url.split('?')
      const URL = new URLSearchParams(queryString)

      for (const key of URL.keys()) {
        if (URL.get(key) === '' || URL.get(key) === null || URL.get(key) === undefined) {
          URL.delete(key)
        }
      }

      return baseUrl + (URL.toString() && `?${URL.toString()}`)
    }

    return url
  }

  onRequest(config: AxiosRequestConfig) {
    // localStorage에 sesstionTime 있으면 현재시간과 비교해 넘었으면 로그아웃
    if (localStorage.getItem('sessionTime')) {
      const sessionTime = Number(localStorage.getItem('sessionTime'))
      const nowTime = new Date().getTime()
      const diffTime = nowTime - sessionTime
      const diffHour = Math.floor(diffTime / 1000 / 60 / 60)

      // 관리자 1시간, 일반 3시간
      const timeoutHour = this.store.getters.isAdmin ? 1 : 3

      if (diffHour >= timeoutHour && process.env.NODE_ENV !== 'development') {
        router.push('/logout')
        this.store.commit('showSnackbar', { text: '인증이 만료되었습니다. 로그인을 해주세요.' })
        localStorage.removeItem('sessionTime')

        return Promise.reject()
      }
    }

    // localStorage에 sesstionTime 저장
    localStorage.setItem('sessionTime', String(new Date().getTime()))

    // 호출 API 공통처리(URL escape)
    config.params = this.onParamsEscape(config.params)
    config.url = this.onQueryStringEscape(config.url as string)

    return config
  }

  /**
   * GET 요청
   * @param URL
   * @param params (not required)
   * @param configs (not required)
   */
  requestGet<T>(URL: string, params?: object, configs?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    // 중복 요청 취소
    if (this.cancelToken) {
      this.cancelToken.cancel()
    }

    this.cancelToken = Axios.CancelToken.source()

    return this.axios.get(URL, {
      ...configs,
      params: params,
      cancelToken: this.cancelToken.token,
    })
  }

  /**
   * POST 요청
   * @param URL
   * @param data (body) (not required)
   * @param configs (not required)
   */
  requestPost<T>(URL: string, data?: object, configs?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    // 중복 요청 취소
    if (this.cancelToken) {
      this.cancelToken.cancel()
    }

    this.cancelToken = Axios.CancelToken.source()

    return this.axios.post(URL, data, {
      ...configs,
      cancelToken: this.cancelToken.token,
    })
  }

  /**
   * PUT 요청
   * @param URL
   * @param data (body) (not required)
   * @param configs (not required)
   */
  requestPut<T>(URL: string, data?: object, configs?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    // 중복 요청 취소
    if (this.cancelToken) {
      this.cancelToken.cancel()
    }

    this.cancelToken = Axios.CancelToken.source()

    return this.axios.put(URL, data, {
      ...configs,
      cancelToken: this.cancelToken.token,
    })
  }

  /**
   * PATCH 요청
   * @param URL
   * @param data (body) (not required)
   * @param configs (not required)
   */
  requestPatch<T>(URL: string, data?: object, configs?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    // 중복 요청 취소
    if (this.cancelToken) {
      this.cancelToken.cancel()
    }

    this.cancelToken = Axios.CancelToken.source()

    return this.axios.patch(URL, data, {
      ...configs,
      cancelToken: this.cancelToken.token,
    })
  }

  /**
   * DELETE 요청
   * @param URL
   * @param configs (not required)
   */
  requestDelete<T>(URL: string, configs?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    // 중복 요청 취소
    if (this.cancelToken) {
      this.cancelToken.cancel()
    }

    this.cancelToken = Axios.CancelToken.source()

    return this.axios.delete(URL, {
      ...configs,
      cancelToken: this.cancelToken.token,
    })
  }
}

export default Api
