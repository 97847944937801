
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'ListSize',

    props: {
      limitList: {
        type: Array,
        default: () => [10, 20, 50, 100],
      },
    },

    emits: ['change'],

    computed: {
      size: {
        get() {
          return this.$store.getters.listSize
        },
        set(value) {
          this.$store.commit('listSize', value)
        },
      },
    },

    methods: {
      changeSize() {
        localStorage.setItem('listSize', this.size)
        this.$store.commit('listSize', this.size)
        this.$emit('change')
      },
    },
  })
