
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'SnackbarComponent',

    data() {
      return {
        //
      }
    },

    mounted() {
      //
    },

    methods: {
      //
    },
  })
