import Api, { ApiConfig } from '@/api/base/api'
import { CancelTokenSource, AxiosRequestConfig } from 'axios'

class ProductsApi extends Api {
  public cancelToken: CancelTokenSource | null = null

  constructor(configs?: ApiConfig) {
    super({
      baseURL: `${process.env.VUE_APP_SERVER_ENDPOINT_ADMIN}`,
      ...configs,
    })
  }

  /**
   * [상품 목록] 조회
   * @param params 검색 필터
   */
  getProducts(params: Product.RequestProducts) {
    return this.requestGet<Common.TApiResponseWrapper<Product.ProductContentsPage>>('/v2/products', {
      ...params,
    })
  }

  /**
   * [상품정보 일괄수정 예약] 요청
   * @param data 상품정보 일괄수정 데이터
   */
  putProductsBulkUpdate(data: Product.UpdateBatchProductInfo) {
    return this.requestPut<Common.TApiResponseWrapper<Product.BasicResponse>>('/bulk-products/infos', data)
  }

  /**
   * [가격/재고관리 목록] 조회
   * @param params 검색 필터
   */
  getSaleAndInventorySearch(params: Product.SaleAndInventorySearch) {
    return this.requestGet<Common.TApiResponseWrapper<Product.ProductContentsPage>>('/products/saleAndInventory/search', {
      ...params,
    })
  }

  /**
   * [가격/재고관리 엑셀 다운로드]
   * @param data 엑셀다운로드 요청 데이터
   */
  postSaleAndInventoryUploadExcelDownload(data: Product.CommonProductExcelSearch) {
    return this.requestPost<Common.TApiResponseWrapper<Product.ProductsSaleAndInventoryExcelDownload[]>>(
      '/products/saleAndInventory/uploadExcelDownload',
      data
    )
  }

  /**
   * [재고관리 옵션 가격정보 일괄수정]
   * @param productNo 상품번호
   * @param data 재고관리 옵션 가격정보(Array) 요청 데이터
   */
  patchSaleAndInventoryBatch(productNo: number, data: Product.UpdateBatchProductInventory<Product.BatchProductInventoryItem>) {
    return this.requestPatch(`/products/saleAndInventory/${productNo}/inventory/batch`, data)
  }

  /**
   * [재고관리 가격정보 일괄수정]
   * @param data 가격정보 일괄수정 요청 데이터
   */
  patchSaleAndInventorySalesBatch(data: Product.UpdateBatchProductInventory<Product.BatchProductSaleItem>) {
    return this.requestPatch(`/products/saleAndInventory/sales/batch`, data)
  }

  /**
   * [할인율 일괄수정]
   * @param data 할인율 일괄수정 요청 데이터
   */
  patchSaleAndInventorySaleBatchExcelUpload(data: Product.UpdateBatchProductInventory<Product.BatchProductSaleExcelItem>) {
    return this.requestPatch(`/products/saleAndInventory/sales/batch/excelUpload`, data)
  }

  /**
   * [브랜드 일괄수정 예약] 요청
   * @param data 브랜드 일괄수정 데이터
   */
  postBrandBulkUpdate(data: Product.BulkBrandIdAndProductNo) {
    return this.requestPost<Common.TApiResponseWrapper<Product.BasicResponse>>('/brands/bulk-update-mapping', data)
  }

  /**
   * [브랜드 생성]
   * @param data 브랜드 생성 데이터
   */
  postBrands(data: Product.BrandCreateRequest) {
    return this.requestPost<Common.TApiResponseWrapper<Product.BasicResponse>>('/brands', data)
  }

  /**
   * [이미지 리사이즈 생성]
   * @param data 이미지 생성 데이터
   * @param config API 설정
   */
  postImagesResizeImages(data: Product.ResizeImageRequest, config?: AxiosRequestConfig) {
    return this.requestPost<Common.TApiResponseWrapper<Product.ImageResizeProcessResponse>>('/images/resize-images', data, config)
  }

  /**
   * [상품 기본옵션 조회]
   */
  getProductsOptionBasic() {
    return this.requestGet<Common.TApiResponseWrapper<Product.ResponseBasicOptionInfo>>('/products/option/basic')
  }
}

export default ProductsApi
