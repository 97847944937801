import { defineComponent } from 'vue'
import Axios from 'axios'

export default defineComponent({
  name: 'BankVerificationComponent',

  props: {
    bankVerified: { type: Boolean, default: false },
    bankCode: { type: String, default: null },
    bankAccount: { type: String, default: '' },
    bankAccountHolder: { type: String, default: '' },
    type: { type: String, required: true }, // seller, customer, NE, NA, NENA
    disabled: { type: Boolean, default: false },
    isExceptVerification: { type: Boolean, default: false }, // 인증제외 여부(값)
  },

  emits: ['update:bankCode', 'update:bankAccount', 'update:bankAccountHolder', 'update:bankVerified', 'update:isExceptVerification'],

  data() {
    return {
      verifying: false,
      verified: this.bankVerified,

      // Validation
      rules: {
        bank: [(v) => !!v || '은행을 선택해주세요.'],
        account: [(v) => !!v || '필수 입력값입니다.', (v) => /^\d+$/.test(v) || '숫자만 입력해주세요.'],
        accountHolder: [
          (v) => !!v || '필수 입력값입니다.',
          (v) =>
            !v.match(
              /(\uD800\uDC00-\uDBFF\uDFFF|\u00a9|\u00ae|[\u2000-\u3100]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff]|[0-9]\ufe0f\u20e3|\u1f51f+)/g
            ) || '이모지는 사용할 수 없습니다.',
        ],
      },
    }
  },

  computed: {
    bankItems() {
      switch (this.type) {
        case 'seller':
          return this.$store.getters.bankItemsSeller
        case 'customer':
          return this.$store.getters.bankItemsCustomer
        case 'NE':
          return this.$store.getters.bankItemsNE
        case 'NA':
          return this.$store.getters.bankItemsNA
        case 'NENA':
          return this.$store.getters.bankItemsNENA
        default:
          return []
      }
    },
    bank: {
      get() {
        return this.bankCode
      },
      set(value) {
        this.$emit('update:bankCode', value)
      },
    },
    account: {
      get() {
        return this.bankAccount
      },
      set(value) {
        this.$emit('update:bankAccount', value)
      },
    },
    accountHolder: {
      get() {
        return this.bankAccountHolder
      },
      set(value) {
        this.$emit('update:bankAccountHolder', value)
      },
    },
    isExcept: {
      get() {
        return this.isExceptVerification
      },
      set(value) {
        this.$emit('update:isExceptVerification', value)
      },
    },
    // [무통장, 셀러] 계좌 인증 사용여부
    useVerification() {
      return ['NA', 'seller'].includes(this.type)
    },
    // NA(무통장입금) 인증제외 사용여부
    useExceptVerification() {
      return this.type === 'NA'
    },
    isOverseas() {
      return this.bankItems.find((item) => item.value === this.bank)?.isOverseas
    },
    isReadOnlyAccountHolder() {
      switch (true) {
        // 결제인증 필요 && 인증제외일 경우
        // 해외은행 계좌일 경우
        // 결제인증 비필요 && 은행 선택됨
        case this.useVerification && this.isExcept && !!this.bank:
        case this.isOverseas:
        case !this.useVerification && !!this.bank:
          return false

        default:
          return true
      }
    },
  },

  watch: {
    verified() {
      this.$emit('update:bankVerified', this.verified)
    },
  },

  methods: {
    async verify() {
      const bankError = await this.$refs.bank.validate()
      const accountError = await this.$refs.account.validate()
      const accountHolderError = await this.$refs.accountHolder.validate()

      if (bankError[0] || accountError[0] || accountHolderError[0]) {
        this.$store.commit('showSnackbar', { text: '입력값을 확인해주세요.', type: 'error' })
        return
      }
      this.verifying = true

      Axios.post(`${process.env.VUE_APP_SERVER_ENDPOINT_SELLER}/seller/validate/bank-account`, {
        bankCode: this.bank,
        accountNumber: this.account,
      })
        .then((res) => {
          this.verifying = false

          if (res.data.data.isValidated) {
            this.verified = true
            this.$store.commit('showSnackbar', { text: '계좌 인증에 성공했습니다.' })
            this.accountHolder = res.data.data.accountHolder
          } else {
            this.$store.commit('showSnackbar', { text: '계좌 인증에 실패했습니다. 계좌번호와 예금주명을 확인해 주세요.', type: 'error' })
          }
        })
        .catch(() => {
          this.verifying = false
        })
    },

    reset() {
      this.verified = false
      this.accountHolder = ''
    },

    onEnter(event) {
      if (event.isComposing || event.keyCode === 229) return
      const bank = this.bankItems.find((item) => item.title.includes(event.target.value.toUpperCase()))

      if (bank) {
        this.bank = bank.value
        this.$refs.account.focus()
      }
    },
  },
})
