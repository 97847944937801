import Api, { ApiConfig } from '@/api/base/api'
import { CancelTokenSource } from 'axios'

class SellerApi extends Api {
  public cancelToken: CancelTokenSource | null = null

  constructor(configs?: ApiConfig & { headers?: { [key: string]: string | number | object | null | undefined | void } }) {
    super({
      baseURL: `${process.env.VUE_APP_SERVER_ENDPOINT_SELLER}`,
      ...configs,
    })
  }

  /**
   * 셀러 정보 조회
   * @param sellerNo 셀러 번호
   */
  getSellerInfo(sellerNo: number) {
    return this.requestGet<Common.TApiResponseWrapper>(`/seller/${sellerNo}`)
  }
}

export default SellerApi
