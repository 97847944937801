import { defineComponent, PropType } from 'vue'

type Density = 'compact' | 'default' | 'comfortable'
type hideDetails = boolean | 'auto' | undefined

export default defineComponent({
  name: 'DatePickerComponent',

  props: {
    modelValue: { type: [String, Object], default: '' },
    disabled: { type: Boolean, default: false }, // 텍스트 필드 비활성화
    dimed: { type: Boolean, default: false }, // 텍스트 필드 회색
    clearable: { type: Boolean, default: false }, // 텍스트 필드 클리어 버튼
    density: { type: String as PropType<Density>, default: 'compact' }, // 텍스트 필드 크기
    hideDetails: { type: [Boolean, String] as PropType<hideDetails>, default: false }, // 텍스트 필드 상세 표시
    placeholder: { type: String, default: '날짜' }, // 텍스트 필드 플레이스홀더
    range: { type: Boolean, default: false }, // 단일날짜인지 기간인지
    min: { type: String, default: '' }, // 최소 날짜 제한
    max: { type: String, default: '' }, // 최대 날짜 제한
    mode: { type: String, default: 'date' }, // 날짜만인지 시간까지 포함인지 (date | datetime)
    format: { type: String, default: 'YYYY-MM-DD' }, // 날짜 포맷
    noIcon: { type: Boolean, default: false }, // 텍스트 필드 앞에 아이콘 표시 여부
    smallText: { type: Boolean, default: false }, // 텍스트 필드 글자 작게
    required: { type: Boolean, default: false }, // 필수 입력 여부
    suffix: { type: String, default: '' }, // 텍스트 필드 뒤에 붙는 텍스트
    prefix: { type: String, default: '' }, // 텍스트 필드 앞에 붙는 텍스트
    maxRange: { type: Number, default: 0 }, // 기간선택 최대 기간
  },

  emits: ['update:modelValue', 'change', 'click:clear'],

  data() {
    return {
      menu: false, // 달력 온픈
      label: '', // 텍스트 필드에 표시할 날짜
      dirty: false, // 텍스트 필드에 값이 변경되었는지 여부
      requiredRules: [(v) => !!v || '필수 입력입니다.'],
    }
  },

  computed: {
    isRequired() {
      return this.required
    },
    date: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      },
    },
  },

  watch: {
    date() {
      // mode가 date인 경우 달력을 닫고 시간까지 포함한 경우 달력을 열어둠
      if (this.mode === 'date') {
        this.menu = false
      }

      this.setLabel()
    },
    range() {
      // 텍스트 필드에 값이 변경되었을 때 텍스트 필드에 표시할 날짜를 변경
      this.setLabel()
    },
    modelValue() {
      this.date = this.modelValue
      this.dirty = true
    },
  },

  mounted() {
    // 기간선택일 경우 에러방지를 위해 기본값 설정
    if (this.mode === 'date' && this.range && !this.modelValue) {
      this.date = { start: '', end: '' }
    }

    this.setLabel()
  },

  methods: {
    clear() {
      this.date = ''
      this.$emit('update:modelValue', null)
      this.$emit('click:clear')
    },

    submit() {
      this.menu = false

      if (this.range && this.date && this.date.start && this.date.end && this.maxRange) {
        // 3개월 이상이면 3개월로 제한
        if (this.$moment(this.date.end).diff(this.$moment(this.date.start), 'months') >= this.maxRange) {
          this.$store.commit('showSnackbar', { text: '3개월 이상 선택할 수 없어 3개월로 설정되었습니다.', type: 'error' })

          const cloneDate = {
            start: this.date.start,
            end: this.$moment(this.date.start).add(3, 'months').format('YYYY-MM-DD'),
          }

          this.date = cloneDate
        }
      }

      this.$emit('update:modelValue', this.date)
      this.$emit('change')
    },

    setLabel() {
      if (this.range) {
        // 기간선택일 경우
        if (this.date && this.date.start && this.date.end) {
          this.label = `${this.filterText(this.date.start)} ~ ${this.filterText(this.date.end)}`
        } else {
          this.label = ''
        }
      } else {
        // 단일날짜일 경우
        this.label = this.date ? `${this.filterText(this.date)}` : ''
      }
    },

    // 오늘 날짜일 경우 '오늘' 표시
    filterText(date: string | object) {
      if (typeof date === 'string') {
        const textArr = date.split(' ')
        if (textArr[0] === this.$moment().format('YYYY-MM-DD')) {
          return '오늘' + (textArr[1] ? ` ${textArr[1]}` : '')
        } else {
          return date
        }
      }
    },

    // 달력 외부 클릭시 달력 닫기
    onClickOutside() {
      this.menu = false
    },
  },
})
