import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import filters from './plugins/filters'
import moment from 'moment'
import Axios from 'axios'
import VCalendar from 'v-calendar'
import { loadFonts } from './plugins/webfontloader'
import { VueClipboard } from '@soerenmartius/vue3-clipboard'
import VueCookies from 'vue-cookies'
import { createHead } from '@vueuse/head'

// 공용 컴포넌트
import DatePicker from './components/DatePickerComponent.vue'
import Confirmation from './components/ConfirmationComponent.vue'
import DevTool from './components/DevToolComponent.vue'
import QuickPeriod from './components/QuickPeriodComponent.vue'
import PhoneVerification from './components/PhoneVerificationComponent.vue'
import EmailVerification from './components/EmailVerificationComponent.vue'
import BankVerification from './components/BankVerificationComponent.vue'
import Clipboard from './components/ClipboardComponent.vue'
import ListSize from './components/ListSizeComponent.vue'
import CategorySelect from './components/CategorySelectComponent.vue'

loadFonts()
const VueHead = createHead()

const app = createApp(App)
  .use(router)
  .use(store)
  .use(VueClipboard)
  .use(VCalendar, {})
  .use(vuetify)
  .use(VueCookies, { expires: '7d' })
  .use(VueHead)

app.config.globalProperties.$moment = moment
app.config.globalProperties.$filters = filters

app.component('DatePicker', DatePicker)
app.component('Confirmation', Confirmation)
app.component('DevTool', DevTool)
app.component('QuickPeriod', QuickPeriod)
app.component('PhoneVerification', PhoneVerification)
app.component('EmailVerification', EmailVerification)
app.component('BankVerification', BankVerification)
app.component('Clipboard', Clipboard)
app.component('ListSize', ListSize)
app.component('CategorySelect', CategorySelect)

// Axios 기본세팅
Axios.defaults.headers.common['service-no'] = store.getters.serviceNo
Axios.defaults.headers.common['authorization'] = localStorage.getItem('accessToken')

// Axios 에러핸들러
Axios.interceptors.response.use(undefined, function (error) {
  if (error && error.response) {
    if ([401, 403].includes(error.response.data?.error?.code) || [401, 403].includes(error.response.status)) {
      router.push('/logout')
      store.commit('showSnackbar', { text: '인증이 만료되었습니다. 로그인을 해주세요.' })

      return Promise.reject(error)
    }

    // 로그인 중 메인터넌스
    if ([503].includes(error.response.data?.error?.code) && localStorage.getItem('accessToken')) {
      store.commit('setMaintenanceText', { text: error.response.data.error.message, isMaintenance: true })
      return Promise.reject(error)
    }

    // order-api 에러 공통처리
    if (error.request?.responseURL.includes('https://order-api')) {
      if (error.response.data?.error?.code === 400) {
        store.commit('closeErrorBanner')
        store.commit('endLoading')
        store.commit('showSnackbar', { text: error.response.data.error.message, type: 'error' })

        return Promise.reject(error)
      }
    }

    // order-api 에러 공통처리
    if (error.request?.responseURL.includes('https://admin-api')) {
      if (error.response.data?.error?.code === 400) {
        store.commit('closeErrorBanner')
        store.commit('endLoading')
        store.commit('showSnackbar', { text: error.response.data.error.message, type: 'error' })

        return Promise.reject(error)
      }
    }
  }
  store.commit('endLoading')
  store.commit('setMaintenanceText', { text: null, isMaintenance: false })
  store.commit('openErrorBanner', error.response.data?.error?.message || error)
  return Promise.reject(error)
})

// Axios 선처리 핸들러
Axios.interceptors.request.use((config) => {
  // localStorage에 sesstionTime 있으면 현재시간과 비교해 넘었으면 로그아웃
  if (localStorage.getItem('sessionTime')) {
    const sessionTime = Number(localStorage.getItem('sessionTime'))
    const nowTime = new Date().getTime()
    const diffTime = nowTime - sessionTime
    const diffHour = Math.floor(diffTime / 1000 / 60 / 60)

    // 관리자 1시간, 일반 3시간
    const timeoutHour = store.getters.isAdmin ? 1 : 3

    if (diffHour >= timeoutHour && process.env.NODE_ENV !== 'development') {
      router.push('/logout')
      store.commit('showSnackbar', { text: '인증이 만료되었습니다. 로그인을 해주세요.' })
      localStorage.removeItem('sessionTime')

      return Promise.reject()
    }
  }

  // localStorage에 sesstionTime 저장
  localStorage.setItem('sessionTime', String(new Date().getTime()))

  // order-api 공통처리
  if (config.url?.includes('https://order-api')) {
    if (config.headers) {
      config.headers['seller-no'] = Number(store.getters.sellerNo)
    }
  }

  // admin-api 공통처리
  if (config.url?.includes('https://admin-api')) {
    if (config.headers) {
      config.headers['referer-type'] = 'new-admin'
    }
  }

  const params = new URLSearchParams()

  // 파라미터 null값 제거
  for (const key in config.params) {
    if (config.params[key] !== null) {
      params.append(key, config.params[key])
    }
  }

  config.params = params

  return config
})

app.mount('#app')
