import { createStore } from 'vuex'
import CreateProduct from './store-create-product'
import Bank from './store-bank'
import deliveryCompanies from './store-delivery-company'
import Axios from 'axios'

export default createStore({
  state: {
    token: null as string | null, // 토큰
    drawer: true, // 네비게이션 드로워 여부
    rightDrawer: false, // 우측 드로워(Dev Tool)
    loading: false, // 로딩 여부
    zoomOut: false, // 줌아웃 여부
    pageTitle: '', // 페이지 타이틀
    listSize: 10, // 페이지당 리스트 사이즈
    snackbar: false, // 스낵바 여부
    snackbarText: '', // 스낵바 텍스트
    snackbarType: '', // 스낵바 타입
    viewOption: 'card', // 상품 리스트 뷰 옵션
    dirty: false, // 페이지 이동시 경고 여부
    errorBanner: false, // 에러 배너 여부
    errorBannerText: '', // 에러 배너 텍스트
    sellerInfo: {} as Login.SellerInfo, // 셀러 정보
    adminInfo: {} as Login.SellerInfo, // 셀러 정보
    channelSectionItems: [] as { title: string; value: number }[], // 채널 셀러속성 전체 목록
    sellerSectionItems: [] as { title: string; value: number }[], // 셀러속성 목록
    lastProductListFullpath: null, // 뒤로가기를 위한 상품리스트 쿼리
    isExcelPassword: true, // 엑셀 비밀번호 설정 여부
    menus: null, // 메뉴 권한 목록
    isMaintenance: false, // 메인터넌스 여부
    maintenanceText: '서비스 점검 중입니다.', // 메인터넌스 점검 시간
    channelCode: null, // 채널 코드
    // 채널 목록
    channels: [
      { code: 'brandi', serviceNo: 1, name: '브랜디', logo: 'logo_b.png', favicon: '/b-icon.png', domain: 'brandi.co.kr' },
      { code: 'hiver', serviceNo: 2, name: '하이버', logo: 'logo_h.png', favicon: '/h-icon.png', domain: 'hiver.co.kr' },
      { code: 'sstore', serviceNo: 5, name: '서울스토어', logo: 'logo_s.png', favicon: '/ss-icon.png', domain: 'seoulstore.com' },
      { code: 'helpi', serviceNo: 1, name: '헬피', logo: 'logo_helpi.png', favicon: '/b-icon.png', domain: 'helpi.co.kr' },
      { code: 'selpi', serviceNo: 3, name: '셀피', logo: 'logo_selpi.png', favicon: '/selpi-icon.svg', domain: 'selpi.co.kr' },
    ],
    // 셀러속성 목록
    sellerStatusItems: [
      { value: 'SW', title: '입점대기' },
      { value: 'ST', title: '입점' },
      { value: 'BR', title: '휴점' },
      { value: 'CW', title: '퇴점대기' },
      { value: 'CL', title: '퇴점' },
      { value: 'SD', title: '입점거절' },
    ],
    // 진열상태 목록
    exhibitionStatusItems: [
      { title: '진열', value: 'DISPLAY' },
      { title: '미진열', value: 'NOT_DISPLAY' },
      { title: '미진열(직권)', value: 'M_NOT_DISPLAY' },
    ],
    // 판매상태 목록
    sellStatusItems: [
      { title: '판매', value: 'SALE' },
      { title: '미판매', value: 'NOT_SALE' },
      { title: '미판매(직권)', value: 'M_NOT_SALE' },
    ],
    // 할인여부 목록
    discountItems: [
      { title: '할인 중', value: true },
      { title: '미할인', value: false },
    ],
    // 주문상태 목록
    orderStatusItems: [
      { title: '상품준비', value: '상품준비', color: 'primary-lighten-1' },
      { title: '배송준비', value: '배송준비', color: 'amber' },
      { title: '배송중', value: '배송중', color: 'amber-darken-1' },
      { title: '배송완료', value: '배송완료', color: 'info' },
      { title: '구매확정', value: '구매확정', color: 'green' },
      { title: '환불요청', value: '환불요청', color: 'red' },
      { title: '반품진행', value: '반품진행', color: 'amber-darken-1' },
      { title: '환불승인중', value: '환불승인중', color: 'red-lighten-1' },
      { title: '환불완료', value: '환불완료', color: 'charcoal' },
      { title: '주문취소중', value: '주문취소중', color: 'red-lighten-1' },
      { title: '주문취소완료', value: '주문취소완료', color: 'charcoal' },
    ],
    // 새로운 주문상태 목록
    orderStatusItems2: [
      { title: '상품준비', value: '4', color: 'primary-lighten-1' },
      { title: '배송준비', value: '10', color: 'amber' },
      { title: '배송중', value: '5', color: 'amber-darken-1' },
      { title: '배송완료', value: '6', color: 'info' },
      { title: '구매확정', value: '9', color: 'green' },
      { title: '환불요청', value: '12', color: 'red' },
      { title: '반품진행', value: '13', color: 'amber-darken-1' },
      { title: '환불승인중', value: '14', color: 'red-lighten-1' },
      { title: '환불완료', value: '7', color: 'charcoal' },
      { title: '주문취소중', value: '11', color: 'red-lighten-1' },
      { title: '주문취소완료', value: '8', color: 'charcoal' },
    ],
    // 정산상태 목록
    payoutStatusItems: [
      { title: '지급대기', value: '지급대기', code: 'ready', color: 'primary-lighten-1' },
      { title: '정산완료', value: '지급완료', code: 'complete', color: 'green' },
      { title: '정산보류', value: '지급보류', code: 'deferred', color: 'error' },
    ],
    // 시즌 목록
    seasonItems: [
      { value: 'NONE', title: '시즌값없음' },
      { value: 'SPRING', title: 'Spring' },
      { value: 'SUMMER', title: 'Summer' },
      { value: 'FALL', title: 'Fall' },
      { value: 'WINTER', title: 'Winter' },
      { value: 'SPRNSUME', title: 'S/S(봄여름)' },
      { value: 'FALLWNTR', title: 'F/W(가을겨울)' },
      { value: 'SPRNFALL', title: 'S/F(봄가을)' },
      { value: 'ALL', title: 'All(4계절)' },
    ],
    // 환불사유 목록
    refundReasonItems: [
      { value: 'REASON_2', title: '고객변심' },
      { value: 'REASON_3', title: '상품문제' },
      { value: 'REASON_4', title: '배송문제' },
      { value: 'REASON_8', title: '기타' },
    ],
    // 환불상세사유 목록
    refundDetailReasonItems: [
      { value: 'REASON_21', title: '구매 의사 없어짐', parent: 'REASON_2' },
      { value: 'REASON_22', title: '더 저렴한 상품을 발견함', parent: 'REASON_2' },
      { value: 'REASON_23', title: '브랜디에서 다른상품으로 재주문', parent: 'REASON_2' },
      { value: 'REASON_24', title: '타 사이트 상품 구매', parent: 'REASON_2' },
      { value: 'REASON_25', title: '동일상품 재주문(결제/주문정보 수정)', parent: 'REASON_2' },
      { value: 'REASON_26', title: '단순 변심으로 인한 교환 요청', parent: 'REASON_2' },
      { value: 'REASON_31', title: '상품의 구성품/부속품이 들어있지않음', parent: 'REASON_3' },
      { value: 'REASON_32', title: '상품의 상세설명이 잘못됨', parent: 'REASON_3' },
      { value: 'REASON_33', title: '상품 결함/기능에 이상이 있음', parent: 'REASON_3' },
      { value: 'REASON_34', title: '주문 상품의 품절', parent: 'REASON_3' },
      { value: 'REASON_35', title: '상품 문제로 인한 교환 요청', parent: 'REASON_3' },
      { value: 'REASON_41', title: '다른 주소로 배송됨', parent: 'REASON_4' },
      { value: 'REASON_42', title: '다른 상품이 배송됨', parent: 'REASON_4' },
      { value: 'REASON_43', title: '일부 상품이 배송되지 않음', parent: 'REASON_4' },
      { value: 'REASON_44', title: '배송처리가 늦음', parent: 'REASON_4' },
      { value: 'REASON_81', title: '기타 사유로 반품 배송비 판매자 부담', parent: 'REASON_8' },
    ],

    // 취소사유 목록
    cancelReasonItems: [
      // { value: 'BUYER_CANCEL', title: '구매자취소' },
      { value: 'BUYER_CHANGE', title: '구매자변심' },
      { value: 'PRODUCT_SOLDOUT', title: '상품품절', isNaverpay: false },
      { value: 'ETC', title: '기타', isNaverpay: true },
    ],

    // 컬러코드
    colorOptions: [
      { id: '00', title: '선택안함', value: 'NONE', style: 'box-shadow: none !important; color: grey' },
      { id: '01', title: '레드', value: 'RED', style: 'background: #FF3839' },
      { id: '02', title: '오렌지', value: 'ORANGE', style: 'background: #ff7800' },
      { id: '03', title: '옐로우', value: 'YELLOW', style: 'background: #ffd333' },
      { id: '04', title: '베이지', value: 'BEIGE', style: 'background: #ebd7b7' },
      { id: '05', title: '브라운', value: 'BROWN', style: 'background: #92542e' },
      { id: '06', title: '그린', value: 'GREEN', style: 'background: #319760' },
      { id: '07', title: '민트', value: 'MINT', style: 'background: #bbf3ec' },
      { id: '08', title: '스카이', value: 'SKY', style: 'background: #94cbfe' },
      { id: '09', title: '블루', value: 'BLUE', style: 'background: #3a72e6' },
      { id: '10', title: '네이비', value: 'NAVY', style: 'background: #001c69' },
      { id: '11', title: '퍼플', value: 'PURPLE', style: 'background: #9743d5' },
      { id: '12', title: '핑크', value: 'PINK', style: 'background: #fe6e9a' },
      { id: '13', title: '화이트', value: 'WHITE', style: 'background: #ffffff; border: 1px solid #eee; color: #666' },
      { id: '14', title: '그레이', value: 'GRAY', style: 'background: #abb3bd' },
      { id: '15', title: '블랙', value: 'BLACK', style: 'background: #202429' },
      { id: '16', title: '골드', value: 'GOLD', style: 'background: linear-gradient(140deg, #fee9b4 10%, #f0be48 100%)' },
      { id: '17', title: '로즈골드', value: 'ROSE_GOLD', style: 'background: linear-gradient(140deg, #ffe5d3 10%, #faa66b 100%)' },
      { id: '18', title: '실버', value: 'SILVER', style: 'background: linear-gradient(140deg, #eaeaeb 10%, #b1b2b3 100%)' },
      { id: '19', title: '와인', value: 'WINE', style: 'background: #a30105' },
      { id: '20', title: '라벤더', value: 'LAVENDER', style: 'background: #e1d0f6' },
      { id: '21', title: '카키', value: 'KHAKI', style: 'background: #9c8a58' },
    ],

    // 스타일코드
    styleOptions: [
      { id: '00', title: '선택안함', value: 'NONE' },
      { id: '01', title: '심플베이직', value: 'SIMPLE_BASIC' },
      { id: '02', title: '러블리', value: 'LOVELY' },
      { id: '03', title: '페미닌', value: 'FEMININE' },
      { id: '04', title: '캐주얼', value: 'CASUAL' },
      { id: '05', title: '섹시글램', value: 'SEXY_GLAM' },
      { id: '06', title: '오피스룩', value: 'OFFICE_LOOK' },
      { id: '07', title: '스포츠', value: 'SPORTS' },
      { id: '08', title: '바캉스', value: 'VACATION' },
    ],

    // 서비스 별 카카오톡 채널
    kakaoChannels: {
      brandi: '_pSxoZu',
      hiver: '_yCxkxhC',
      sstore: '_KmJxfxj',
      helpi: '_Nxhxexod',
    },

    // 상품 기본옵션 목록 (색상, 사이즈)
    productBasicOptions: [],
    // document title
    documentTitle: null,
  },
  getters: {
    token: (state) => state.token,
    isLogin: () => !!localStorage.getItem('accessToken'),
    appEnv: () => process.env.NODE_ENV,
    drawer: (state) => state.drawer,
    pageTitle: (state) => state.pageTitle,
    loading: (state) => state.loading,
    zoomOut: (state) => state.zoomOut,
    listSize: (state) => state.listSize,
    menus: (state) => state.menus,
    snackbar: (state) => state.snackbar,
    snackbarText: (state) => state.snackbarText,
    snackbarType: (state) => state.snackbarType,
    viewOption: (state) => state.viewOption,
    sellerInfo: (state) => state.sellerInfo,
    sellerNo: (state) => state.sellerInfo.sellerNo,
    sellerStatus: (state) => state.sellerInfo.sellerStatus,
    sellerStatusText: (state) => state.sellerStatusItems.find((item) => item.value === state.sellerInfo.sellerStatus)?.title || '',
    adminInfo: (state) => state.adminInfo,
    isAdmin: (state) => state.adminInfo.accountType === 1,
    isClosedSeller: (state) => state.sellerInfo.sellerStatus !== 'ST',
    isExcelPassword: (state) => state.isExcelPassword,
    exhibitionStatusItems: (state) => state.exhibitionStatusItems,
    sellStatusItems: (state) => state.sellStatusItems,
    discountItems: (state) => state.discountItems,
    orderStatusItems: (state) => state.orderStatusItems,
    orderStatusItems2: (state) => state.orderStatusItems2,
    payoutStatusItems: (state) => state.payoutStatusItems,
    seasonItems: (state) => state.seasonItems,
    refundReasonItems: (state) => state.refundReasonItems,
    refundDetailReasonItems: (state) => state.refundDetailReasonItems,
    cancelReasonItems: (state) => state.cancelReasonItems,
    lastProductListFullpath: (state) => state.lastProductListFullpath,
    dirty: (state) => state.dirty,
    channelCode: (state) => {
      if (state.channelCode) {
        // 로그인 이후 채널코드가 있을 경우
        return state.channelCode
      } else {
        // 로그인 이전 채널코드가 없을 경우
        const domain = window.location.hostname

        return state.channels.find((channel) => domain.includes(channel.domain))?.code || 'brandi'
      }
    },
    channelDomain: (state) => {
      return state.channels.find((channel) => channel.code === state.channelCode)?.domain
    },
    channelLogo: (state, getters) => {
      return state.channels.find((channel) => channel.code === getters.channelCode)?.logo
    },
    channelName: (state, getters) => {
      return state.channels.find((channel) => channel.code === getters.channelCode)?.name
    },
    serviceNo: (state, getters) => {
      if (state.sellerInfo.serviceNo) {
        // 로그인 이후 서비스번호가 있을 경우
        return state.sellerInfo.serviceNo
      } else {
        // 로그인 이전 서비스번호가 없을 경우
        return state.channels.find((channel) => channel.code === getters.channelCode)?.serviceNo
      }
    },
    errorBanner: (state) => state.errorBanner,
    errorBannerText: (state) => state.errorBannerText,
    channelSectionItems: (state) => state.channelSectionItems,
    sellerSectionItems: (state) => state.sellerSectionItems,
    styleOptions: (state) => state.styleOptions,
    colorOptions: (state) => state.colorOptions,
    isHelpi: (state) => {
      if (state.sellerInfo.sellerNo && typeof state.sellerInfo.isHelpi === 'boolean') {
        return state.sellerInfo.isHelpi
      } else {
        const domain = window.location.hostname
        return domain.includes('helpi.co.kr')
      }
    },
    isMaintenance: (state) => state.isMaintenance,
    maintenanceText: (state) => state.maintenanceText,
    companyName: (state, getters) => {
      switch (getters.channelCode) {
        case 'brandi':
        case 'helpi':
        case 'selpi':
          return '(주)뉴넥스'
        case 'hiver':
          return '(주)하이버'
        case 'sstore':
          return '(주)인벤트'
        default:
          return '(주)뉴넥스'
      }
    },
    currentKakaoChannel: (state, getters) => {
      return state.kakaoChannels[getters.channelCode]
    },
    productBasicOptions: (state) => state.productBasicOptions,
    documentTitle: (state) => state.documentTitle,
  },
  mutations: {
    setToken(state, token: string) {
      state.token = token
    },
    removeToken(state) {
      state.token = null
    },
    toggleLeftDrawer(state) {
      state.drawer = !state.drawer
    },
    closeLeftDrawer(state) {
      state.drawer = false
    },
    toggleRightDrawer(state) {
      state.rightDrawer = !state.rightDrawer
    },
    setPageTitle(state, title) {
      state.pageTitle = title
    },
    listSize(state, size) {
      state.listSize = size
    },
    startLoading(state) {
      state.loading = true
    },
    endLoading(state) {
      state.loading = false
    },
    startZoomOut(state) {
      state.zoomOut = true
    },
    endZoomOut(state) {
      state.zoomOut = false
    },
    sellerInfo(state, sellerInfo) {
      state.sellerInfo = sellerInfo
    },
    adminInfo(state, adminInfo) {
      state.adminInfo = adminInfo
    },
    menus(state, menus) {
      state.menus = menus
    },
    showSnackbar(state, { text, type } = { text: '', type: '' }) {
      state.snackbar = true

      state.snackbarText = text
      state.snackbarType = type
    },
    resetSnackbar(state) {
      state.snackbar = false
      state.snackbarText = ''
      state.snackbarType = ''
    },
    hideSnackbar(state) {
      state.snackbar = false
    },
    toggleViewOption(state) {
      state.viewOption = state.viewOption === 'card' ? 'table' : 'card'
      localStorage.setItem('listView', state.viewOption)
    },
    openErrorBanner(state, text) {
      state.errorBanner = true
      state.errorBannerText = text
    },
    closeErrorBanner(state) {
      state.errorBanner = false
      state.errorBannerText = ''
    },
    channelCode(state, code) {
      state.channelCode = code
    },
    dirty(state, dirty) {
      state.dirty = dirty
    },
    channelSectionItems(state, items) {
      state.channelSectionItems = items
    },
    sellerSectionItems(state, items) {
      state.sellerSectionItems = items
    },
    lastProductListFullpath(state, query) {
      state.lastProductListFullpath = query
    },
    isExcelPassword(state, isExcelPassword) {
      state.isExcelPassword = isExcelPassword
    },
    setMaintenanceText(state, { text, isMaintenance } = { text: null, isMaintenance: false }) {
      state.maintenanceText = text
      state.isMaintenance = isMaintenance
    },
    setProductBasicOptions(state, options) {
      state.productBasicOptions = options
    },
    setDocumentTitle(state, title) {
      state.documentTitle = title
    },
  },
  actions: {
    // 채널 섹션 목록 가져오기
    async fetchChannelSectionItems({ commit, getters }) {
      const { data } = await Axios.get(`${process.env.VUE_APP_SERVER_ENDPOINT_SELLER}/category/store`, {
        headers: { 'Service-No': getters.serviceNo },
      })

      let channelSectionItems = data.data.map((item: { categoryName: string; categoryNo: number }) => {
        return {
          title: item.categoryName,
          value: item.categoryNo,
        }
      })

      // title: '패션직구' 인것은 제외
      channelSectionItems = channelSectionItems.filter((item) => item.title !== '패션직구')

      commit('channelSectionItems', channelSectionItems)
    },

    // 셀러속성 스토어에 저장
    async fetchSellerSectionItems({ commit, getters }) {
      const { data } = await Axios.get(`${process.env.VUE_APP_SERVER_ENDPOINT_ADMIN}/categories/seller-section`, {
        params: { sellerNo: getters.sellerNo },
        headers: { 'Service-No': getters.serviceNo },
      })

      const sellerSectionItems = await data.data.sellerSectionCategories.map((item: Seller.FetchedSectionItem) => {
        return {
          title: item.sellerSectionGroupName,
          value: item.rootCategoryNo,
        }
      })

      commit('sellerSectionItems', sellerSectionItems)
    },

    // 상품카테고리 가져오기
    async getProductCategoryItems(state, sectionNo) {
      const { data } = await Axios.get(`${process.env.VUE_APP_SERVER_ENDPOINT_ADMIN}/categories`, {
        params: { categoryUpNo: sectionNo },
      })

      const productCategoryItems = data.data.categories.map((item: Product.FetchedCategoryItem) => {
        return {
          title: item.categoryName,
          value: item.categoryNo,
        }
      })

      if (!productCategoryItems.length) {
        return [
          {
            title: '2차카테고리 없음',
            value: sectionNo,
          },
        ]
      }

      return productCategoryItems
    },

    // 도큐멘트 타이틀, 아이콘 변경
    setDocumentTitle({ state, getters, commit }) {
      // 타이틀 변경
      const envName = {
        development: 'Dev',
        sprint: 'Sprint',
        staging: 'Staging',
        production: '',
      }
      commit('setDocumentTitle', `${envName[getters.appEnv]} ${getters.channelName} 셀러어드민`)

      setTimeout(() => {
        // 파비콘 변경
        document.querySelectorAll("link[rel*='icon']").forEach((el) => el.remove())
        const link = document.createElement('link')

        const icon = state.channels.find((channel) => channel.code === state.channelCode)?.favicon || state.channels[0].favicon

        link['type'] = 'image/x-icon'
        link['rel'] = 'shortcut icon'
        link['href'] = icon
        document.getElementsByTagName('head')[0].appendChild(link)
      }, 100)
    },
  },

  modules: {
    CreateProduct,
    Bank,
    deliveryCompanies,
  },
})

export type Store = typeof createStore
