
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'ConfirmationComponent',

    props: {
      modelValue: { type: Boolean, required: true },
      noCancel: { type: Boolean, default: false },
      persistent: { type: Boolean, default: false },
      confirmText: { type: String, default: '확인' },
      maxWidth: { type: Number, default: 390 },
    },

    emits: ['confirm', 'update:modelValue'],

    data() {
      return {
        inputShow: true,
      }
    },

    computed: {
      open: {
        get() {
          return this.modelValue
        },
        set(value) {
          this.$emit('update:modelValue', value)
        },
      },
    },

    watch: {
      modelValue() {
        this.$store.commit('hideSnackbar')
        setTimeout(() => {
          this.$refs.confirmInput?.focus()
        }, 1)
        setTimeout(() => {
          this.inputShow = false
        }, 10)
      },
      open(value) {
        if (value && !this.persistent) {
          window.addEventListener('keydown', this.onKeydown)
        } else {
          window.removeEventListener('keydown', this.onKeydown)
        }
      },
    },

    methods: {
      cancel() {
        if (this.persistent) return

        this.open = false
        this.$emit('update:modelValue', false)
      },

      confirm() {
        setTimeout(() => {
          this.open = false
          this.$emit('confirm')
        }, 10)
      },

      onKeydown(e: KeyboardEvent) {
        if (e.key === 'Enter') {
          this.confirm()
        }
      },
    },
  })
