<template>
  <div class="d-flex align-start">
    <v-text-field
      v-model="value"
      density="compact"
      placeholder="이메일 주소를 입력하세요."
      :readonly="verified || readonly"
      @update:model-value="$emit('update:modelValue', value)">
      <template v-if="verified" #append-inner>
        <v-btn variant="plain" size="small" style="margin-top: -2px" @click="verified = false">변경</v-btn>
        <v-icon color="success">check_circle</v-icon>
      </template>
    </v-text-field>

    <template v-if="!verified && !readonly">
      <v-btn
        v-if="!verifying"
        flat
        color="primary-lighten-1"
        class="ml-1"
        style="height: 42px"
        :disabled="!value"
        @click="sendPhoneVerification">
        인증메일 발송
      </v-btn>
      <v-btn v-else flat color="grey-lighten-3 text-chacoal" class="ml-1" style="height: 42px" @click="sendPhoneVerification">
        인증메일 재발송
      </v-btn>
    </template>
  </div>

  <template v-if="verifying">
    <div class="label">인증번호 입력 ({{ timeout }}초 남음)</div>
    <div class="d-flex align-start">
      <v-text-field
        v-model.trim="verifyNumber"
        density="compact"
        placeholder="6자리 숫자"
        :rules="codeRules"
        @keydown.enter="confirmVerify" />

      <v-btn flat color="primary" class="ml-1" style="height: 42px" :disabled="verifyNumber.length !== 6" @click="confirmVerify">
        확인
      </v-btn>
    </div>
  </template>
</template>

<script type="ts" src="./EmailVerificationComponent.ts" />
