import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (_openBlock(), _createBlock(_component_v_snackbar, {
    modelValue: _ctx.$store.state.snackbar,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.$store.state.snackbar) = $event)),
    color: _ctx.$store.getters.snackbarType ? _ctx.$store.getters.snackbarType : 'green',
    onAfterLeave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$store.commit('resetSnackbar')))
  }, {
    actions: _withCtx(() => [
      _createVNode(_component_v_btn, {
        variant: "text",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$store.commit('hideSnackbar')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(" 닫기 ")
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      (_ctx.$store.getters.snackbarText)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            innerHTML: _ctx.$store.getters.snackbarText
          }, null, 8, _hoisted_1))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createTextVNode(" 저장하였습니다. ")
          ], 64))
    ]),
    _: 1
  }, 8, ["modelValue", "color"]))
}