import Axios from 'axios'

const state = {
  deliveryCompanies: [] as { value: string; title: string }[],
}

const getters = {
  deliveryCompanies: (state) => state.deliveryCompanies,
}

const mutations = {
  setDeliveryCompanies(state, payload) {
    state.deliveryCompanies = payload
  },
}

const actions = {
  async getDeliveryCompanies({ commit }) {
    const response = await Axios.get(`${process.env.VUE_APP_SERVER_ENDPOINT_ORDER}/delivery/company`).then((res) => res.data.data)
    const deliveryCompanies = [] as { value: string; title: string }[]

    for (const company of response) {
      deliveryCompanies.push({ value: company.code, title: company.name })
    }

    // 순서변경

    const desiredOrder = [
      'CJ대한통운',
      '한진택배',
      '롯데택배',
      '우체국택배',
      '로젠택배',
      'CUpost',
      'CVSnet',
      '롯데글로벌',
      '에이씨티앤코아물류',
      'ACI Express',
      '올타코리아',
      '기타',
    ]

    const reorderedCompanies = [] as { value: string; title: string }[]

    // 원하는 순서대로 회사를 재배열
    desiredOrder.forEach((companyTitle) => {
      const company = deliveryCompanies.find((c) => c.title === companyTitle)
      if (company) {
        reorderedCompanies.push(company)
      }
    })

    // 순서 상관 없는 나머지 회사 추가
    deliveryCompanies.forEach((company) => {
      if (!desiredOrder.includes(company.title)) {
        reorderedCompanies.push(company)
      }
    })

    commit('setDeliveryCompanies', reorderedCompanies)
  },
}

export default {
  state,
  mutations,
  getters,
  actions,
}
